import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { Helmet } from 'react-helmet';
import ThriveDown from './ThriveDown.png';
import Customer from './Customer';
import './Splash.scss';
import './CustomerError.scss';

/**
 * The main application component.
 * This should be rendered such that is has a store and routing context have already been provided.
 */
const App = () => {
  const customerName = useSelector((state) => state.customer.get('name'));
  const hasError = useSelector((state) => state.customer.has('error'));
  const isFetching = useSelector((state) => state.customer.get('isFetching'));

  return (
    <div>
      <Helmet
        defaultTitle={customerName}
        titleTemplate={`%s - ${customerName}`}
      />
      <CSSTransition
        classNames='splash'
        in={isFetching}
        timeout={{ enter: 0, exit: 1200 }}
        unmountOnExit={true}
      >
        <div className='Splash'>
          <img alt='ThriveApply' className='Splash__logo' src={ThriveDown} />
          <h1 className='Splash__title'>Thrive Apply</h1>
          <p className='Splash__message'>Loading...</p>
        </div>
      </CSSTransition>
      {hasError && !isFetching ? (
        <div className='CustomerError'>
          <img
            alt='Thrive Apply is down'
            className='CustomerError__logo'
            src={ThriveDown}
          />
          <h1 className='CustomerError__title'>
            Thrive Apply encountered an error
          </h1>
          <p className='CustomerError__message'>Please try again later</p>
        </div>
      ) : null}
      {!hasError && !isFetching ? <Customer /> : null}
    </div>
  );
};

export default App;
