import { normalize } from 'normalizr';
import { decamelize, camelizeKeys } from 'humps';
import { application as applicationSchema } from '../schemas';
import fetchAjax from './fetchAjax';
import {
  APPLICANT_SUBMIT_START,
  APPLICANT_SUBMIT_SUCCESS,
  APPLICANT_SUBMIT_FAILURE,
} from './actionTypes';

const submitApplication =
  ({ applicant, jobListingId, setIsApplicationLoading }) =>
  (dispatch) => {
    if (!jobListingId) {
      throw Error('submitApplication requires a `jobListingId`');
    }

    if (!applicant) {
      throw Error('submitApplication requires a `applicant`');
    }

    const payload = {
      jobListingId: jobListingId,
      applicant: applicant,
    };

    dispatch({
      type: APPLICANT_SUBMIT_START,
      payload: payload,
    });

    // We have to use FormData to post this because it could include a file (resume).
    // But FormData can't deal with nested keys, and on the server-side we want something like:
    // {
    //   applicant: {
    //     first_name: 'John',
    //     last_name: 'Doe',
    //     ...
    //   }
    // }
    //
    // So this converts all of our applicant keys to something rails can deserialize. For example,
    // the above essentially becomes a FormData version of:
    // {
    //   'applicant[first_name]': 'John',
    //   'applicant[last_name]': 'Doe',
    // }
    const body = new FormData();
    Object.keys(applicant).forEach((key) => {
      // The application field data is sometimes a string (e.g. first name) and sometimes an array for
      // multi-value inputs (e.g. race/ethnicity). When constructing the FormData payload, array data must be handled differently
      const fieldValue = applicant[key];
      if (Array.isArray(fieldValue)) {
        fieldValue.forEach((value) => {
          body.append(`job_application[${decamelize(key)}][]`, value);
        });
      } else {
        body.append(`job_application[${decamelize(key)}]`, fieldValue || '');
      }
    });

    fetchAjax({
      url: `/api/v1/job_listings/${jobListingId}/job_applications`,
      options: {
        method: 'POST',
        body: body,
      },
    })
      .then((data) => {
        setIsApplicationLoading(false);
        return {
          type: APPLICANT_SUBMIT_SUCCESS,
          payload: {
            ...payload,
            ...normalize(camelizeKeys(data), {
              jobApplication: applicationSchema,
            }),
          },
        };
      })
      .catch((error) => {
        setIsApplicationLoading(false);
        return {
          type: APPLICANT_SUBMIT_FAILURE,
          payload: {
            ...payload,
            error: error,
          },
        };
      })
      .then(dispatch);
  };

export default submitApplication;
