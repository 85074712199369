import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import getJobListingPlacementType from '../../selectors/getJobListingPlacementType';

import './JobPlacementType.scss';

/**
 * Renders a job listing record's location name.
 */
export const JobPlacementType = ({ placementType }) =>
  placementType ? (
    <span className='JobPlacementType'>{placementType}</span>
  ) : null;

JobPlacementType.propTypes = {
  /**
   * The placement type name for the job listing
   */
  placementType: PropTypes.string,
};

/**
 * Connected JobPlacementType, looks up the placement type provided a `jobListingId` value.
 */
export default connect(
  (state, { jobListingId }) => ({
    placementType: getJobListingPlacementType(state, jobListingId),
  }),
  null,
)(JobPlacementType);
