import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import ApplyModal from './ApplyModal';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import getJobListingUrl from '../../selectors/getJobListingUrl';

export const ApplyButton = ({ jobListingId }) => {
  const { action } = useParams();
  const navigate = useNavigate();
  const isModalOpen = action === 'apply';

  const openModal = () =>
    navigate(getJobListingUrl(jobListingId, 'apply'), { replace: true });

  const closeModal = () =>
    navigate(getJobListingUrl(jobListingId), { replace: true });

  return (
    <div>
      <ButtonPrimary isFullWidth={true} label='Apply' onClick={openModal} />
      {isModalOpen && (
        <ApplyModal
          jobListingId={jobListingId}
          onHide={closeModal}
          show={isModalOpen}
        />
      )}
    </div>
  );
};

ApplyButton.propTypes = {
  jobListingId: PropTypes.number.isRequired,
};

export default ApplyButton;
