import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * Collapses content when the sidebar is reduced (or shows content only when
 * the sidebar is reduced, if `inverted` is set.)
 *
 * If `expandable` is true, the content can be revealed in reduced mode
 * when the sidebar hamburger button is clicked.
 */
const CollapseContent = ({ children, className, expandable, inverted }) => (
  <div
    className={classnames('CollapseContent', className, {
      'CollapseContent--expandable': expandable,
      'CollapseContent--inverted': inverted,
    })}
  >
    {children}
  </div>
);

CollapseContent.propTypes = {
  /**
   * The content to show or hide.
   */
  children: PropTypes.node,

  /**
   * Additional className to apply to the container.
   */
  className: PropTypes.string,

  /**
   * When true, the content can be revealed if the sidebar has an expand button.
   */
  expandable: PropTypes.bool,

  /**
   * When true, logic is inverted: the content will be shown only when the sidebar
   * is reduced, and hidden otherwise.
   */
  inverted: PropTypes.bool,
};

CollapseContent.defaultProps = {
  expandable: false,
  inverted: false,
};

export default CollapseContent;
