import { pure } from 'recompose';
import { Helmet } from 'react-helmet';
import PageContainer from '../PageContainer';

const PrivacyPage = () => (
  <PageContainer fixedWidth={true}>
    <Helmet>
      <title>Privacy</title>
    </Helmet>
    <h2 className='text-center'> PRIVACY POLICY</h2>
    <p className='text-center'>
      <em>Last revised on January 10, 2017.</em>
    </p>

    <p>
      This Privacy Policy (“Policy”) describes the information gathering and
      dissemination practices of <em> Thrive TRM LLC (“THRIVE”) </em>. This
      Policy may be modified at any time. Any modifications to the Policy will
      become effective when posted; and by continuing to use this Website
      following the posting of any such changes, you agree to be bound by the
      Policy, as modified. This Policy outlines <em> THRIVE’s </em>
      practices for handling personal information and data.
    </p>

    <p>
      <em> THRIVE </em> respects individual privacy and values the confidence of
      its customers, employees, consumers, business partners and others. Not
      only does <em> THRIVE </em> strive to collect, use and disclose personal
      information in a manner consistent with the laws of the countries in which
      it does business, it also upholds the highest ethical standards in its
      business practices.
    </p>

    <h3> 1. Your Privacy on the Internet </h3>
    <p>
      This Policy covers the Website and any other website that references this
      Policy. Some <em> THRIVE </em> affiliates, programs and/or sites may have
      their own, possibly different, privacy policies. <em> THRIVE </em>{' '}
      encourages you to read the privacy policies of any of <em> THRIVE </em>’s
      affiliate websites that you may visit. <em> THRIVE </em>’s privacy
      practices reflect its current global principles and standards on handling
      personal information. These principles and standards include the Privacy
      Shield Principles of notice, choice, accountability for onward transfer,
      security, data integrity and purpose limitation, access, and recourse,
      enforcement and liability.
    </p>

    <h3> 2. Collection and Processing of Your Personal Data </h3>
    <p>
      By using this Website, you consent to the collection, processing and use
      of the information as described in this Policy. As may be required by
      applicable law, and from time to time, <em> THRIVE </em> may seek your
      explicit consent to process data and information collected on the Website
      or provided by you. <em> THRIVE </em>
      will not subsequently change the way your personal data is used without
      your consent, as required by applicable law. <em> THRIVE </em> may further
      collect and process any information and data that you provide to us, for
      example, when you register with <em> THRIVE </em>, fill out a form, engage{' '}
      <em> THRIVE </em> to conduct its services, subscribe to newsletters, or
      participate in online surveys, discussion groups or forums, or interact
      with the Website in any other manner.
    </p>

    <h3> 3. Purpose of Collected Data </h3>
    <p>
      <em> THRIVE </em> collects and uses your personal data for the purposes of
      providing its products and services to its customers, communicating with
      corporate business partners about business matters, processing your
      personal data on behalf of corporate customers, providing information on
      its/their services, and conducting related tasks for legitimate business
      purposes.
    </p>

    <p>
      The information <em> THRIVE </em> collects, processes and uses to
      understand your needs and interests helps <em> THRIVE </em> deliver to you
      a consistent and personalized experience in connection with its services
      and work product. <em> THRIVE </em> will use personal data only in ways
      that are consistent with the purposes for which it was collected or
      subsequently authorized by you. <em> THRIVE </em> will take reasonable
      steps to ensure that personal information is relevant to its intended use,
      accurate, complete, and current.
    </p>

    <p>
      <em> THRIVE </em> may use your data in such ways that include, but are not
      limited to:
    </p>
    <ul>
      <li> Delivering the services that you have requested; </li>
      <li>
        {' '}
        Keeping you up to date on the latest search developments, announcements,
        and other information;{' '}
      </li>
      <li>
        {' '}
        Tailoring information about our services to your individual interests;{' '}
      </li>
      <li>
        {' '}
        Providing the ability to create personal profile areas and view
        protected content;{' '}
      </li>
      <li>
        {' '}
        Providing the ability to contact you and provide you with feedback and
        support;{' '}
      </li>
      <li>
        {' '}
        Conducting questionnaires and surveys in order to provide better
        products and services to our customers and end users. Your completion of
        any questionnaire is voluntary;{' '}
      </li>
      <li> Supporting recruitment inquiries; and </li>
      <li>
        {' '}
        Generating general industry reports (which exclude personal or corporate
        identification information) about industry trends such as hiring,
        compensation, etc.{' '}
      </li>
    </ul>

    <h3> 4. Automated Data Collection </h3>
    <p>
      <em> THRIVE </em> may collect information during your visit to the Website
      through automated tools, which include cookies, embedded Internet links
      and other commonly used information-gathering tools. These tools collect
      certain standard information that your browser sends to the Website such
      as your browser type and language, access times and the IP address of the
      website from which you arrived at the Website. This information may be
      used for quality control purposes, to help diagnose problems, to
      administer the Website and to gather demographic information.{' '}
      <em> THRIVE </em> may aggregate such data only in an anonymous way and
      will not tie it to a particular individual and/or company.{' '}
      <em> THRIVE </em> uses cookies to deliver content, to save you having to
      re-enter your user ID and/or password repeatedly, and to customize
      information offerings to how you and others use the Website.
    </p>

    <h3> 5. Embedded Web Links </h3>
    <p>
      Emails from <em> THRIVE </em> to you may use links to direct you to a
      relevant area on the Internet, after redirection through <em> THRIVE </em>
      ’s servers. The redirection system allows <em> THRIVE </em> to change the
      destination URL of these links, if necessary, and to determine the
      effectiveness of our marketing initiatives. In emails, such links also may
      allow <em> THRIVE </em> to determine if you have accessed the link in the
      email, and the information about this interaction may be connected to your
      personal identity. If you do not want <em> THRIVE </em> to collect
      information about the links you clicked, you can simply choose not to
      click on the links in an e-mail that <em> THRIVE </em> sends to you.
    </p>

    <h3> 6. E-mail Addresses </h3>
    <p>
      If you choose to provide <em> THRIVE </em> with your e-mail address,{' '}
      <em> THRIVE </em> may communicate with you via e-mail, but{' '}
      <em> THRIVE </em> will not share your e-mail address outside of{' '}
      <em> THRIVE </em>.
    </p>

    <h3> 7. Personal Data Security </h3>
    <p>
      <em> THRIVE </em> uses industry-standard security technologies and
      procedures to ensure data security and integrity for collected personal
      data. <em> THRIVE </em>
      also uses reasonable organizational, technical and administrative measures
      to protect against unauthorized access, misuse, loss, disclosure,
      alteration and destruction of personal information (under its control)
      about users of its online client services. Unfortunately data transmission
      over the Internet cannot be guaranteed as 100% secure. Therefore, while{' '}
      <em> THRIVE </em> strives to protect your personal data, <em> THRIVE </em>{' '}
      cannot ensure or warrant the security of the Website, and you use it at
      your own risk.
    </p>

    <h3> 8. Opt-Out </h3>
    <p>
      <em> THRIVE </em> offers you the opportunity to choose (opt-out) whether
      personal information is: (a) to be disclosed to a third party, or (b) to
      be used for a purpose other than the purpose for which it was originally
      collected or subsequently authorized by you. For sensitive personal
      information, <em> THRIVE </em> will give you the opportunity to
      affirmatively consent (opt-in) to the disclosure of the information to a
      non-agent third party or the use of the information for a purpose other
      than the purpose for which it was originally collected or subsequently
      authorized by you.
    </p>

    <p>
      <em> THRIVE </em> communicates regularly by email and may also communicate
      by phone. <em> THRIVE </em> may use your email address to confirm your
      registration of an account, to send you information about our services
      and/or your search project, and to send legal notices and other
      disclosures.
    </p>

    <p>You can exercise an opt-out by emailing this link:</p>
    <a href='mailto:contactus@thrivetrm.com'>contactus@thrivetrm.com </a>

    <h3> 9. European Union and Swiss Citizens </h3>
    <p>
      <em> THRIVE </em> complies with the EU-U.S. Privacy Shield Framework as
      set forth by the U.S. Department of Commerce regarding the collection,
      use, and retention of personal information transferred from the European
      Union and Switzerland to the United States. <em> THRIVE </em> has
      certified to the Department of Commerce that it adheres to the Privacy
      Shield Principles. If there is any conflict between the terms in this
      Policy and the Privacy Shield Principles, the Privacy Shield Principles
      shall govern. To learn more about the Privacy Shield program, and to view
      our certification, please visit
    </p>

    <a href='https://www.privacyshield.gov/'>https://www.privacyshield.gov/ </a>

    <p>
      <em> THRIVE </em> maintains an affirmative commitment to the U.S.-Swiss
      Safe Harbor Framework and its principles regarding the collection, use and
      retention of personal data from Switzerland, which will not be affected by{' '}
      <em> THRIVE </em>’s participation in the Privacy Shield. <em> THRIVE </em>{' '}
      certifies that it adheres to the Safe Harbor Privacy Principles of notice,
      choice, onward transfer, security, data integrity, access and enforcement
      with respect to certain Swiss Personnel Data received in the United States
      from its affiliated companies in Switzerland.
    </p>

    <p>
      In compliance with the Privacy Shield Principles and the U.S.-Swiss Safe
      Harbor Framework, <em> THRIVE </em> commits to resolve complaints about
      our collection or use of your personal information. Individuals in the
      European Union and Switzerland with inquiries or complaints regarding our
      Private Shield policy should first contact <em> THRIVE </em> at:
    </p>

    <p>
      <em> Thrive TRM LLC</em>
    </p>
    <p>56 N Haddon Avenue</p>
    <p>Haddonfield, New Jersey 08033</p>
    <p>ATTN: Privacy Officer</p>
    <p />

    <a href='mailto:contactus@thrivetrm.com/'>contactus@thrivetrm.com </a>

    <p>
      <em> THRIVE </em> commits to resolve any complaints about our collection
      or use of your personal data. European Union citizens with inquiries or
      complaints regarding this Policy should first contact <em> THRIVE </em>{' '}
      using the contact information provided above. <em> THRIVE </em> has
      further committed to refer unresolved privacy complaints under the
      U.S.-Swiss Safe Harbor Frameworks to the BBB EU Safe Harbor – an
      independent dispute resolution mechanism – operated by the Council of
      Better Business Bureaus or other EU Data Protection Authorities (DPAs). If
      you do not receive timely acknowledgment of your complaint, or if your
      complaint is not satisfactorily addressed by <em> THRIVE </em>, please
      visit the BBB EU Safe Harbor Web site at
    </p>

    <a href='https://www.bbb.org/us/safe-harbor-complaints'>
      https://www.bbb.org/us/safe-harbor-complaints{' '}
    </a>

    <p>
      or contact the other EU DPA for more information and to file a complaint.
      The BBB EU Safe Harbor or other DPA will establish a panel to investigate
      and resolve complaints brought under the Privacy Shield, and{' '}
      <em> THRIVE </em> will cooperate with this panel. Furthermore,{' '}
      <em> THRIVE </em> will comply with the advice given by data protection
      authorities and take necessary steps to remediate any non-compliance with
      the Privacy Shield Principles.
    </p>

    <p>
      You also may be able to invoke binding arbitration, under certain
      circumstances where permitted by the Privacy Shield program, if you
      believe that there has been a violation of Privacy Shield requirements
      that has not been appropriately addressed by <em> THRIVE </em>.
    </p>

    <p>
      <em> THRIVE’s </em> compliance with its Privacy Shield obligations also is
      subject to investigation and enforcement by the U.S. Federal Trade
      Commission and the U.S. Department of Transportation.
    </p>

    <p>
      <em> THRIVE </em> reserves the right to change its policy from time to
      time, consistent with the Privacy Shield Principles.
    </p>

    <p>
      EU-U.S. Privacy Shield Policy Effective Date: 1/15/2017 (Supersedes:
      U.S.-EU Safe Harbor Statement Effective Date: 3/30/2015)
    </p>

    <h3> 10. Disclosures/Onward Transfers of Personal Data</h3>
    <p>
      Except as otherwise provided herein, <em> THRIVE </em> discloses personal
      data only to third parties who reasonably need to know such data only for
      the scope of the initial transaction and not for other purposes. Such
      recipients must agree to abide by confidentiality obligations.
    </p>

    <p>
      <em> THRIVE </em> may provide your personal data to third parties that act
      as agents, consultants or contractors to perform tasks on behalf of and
      under
      <em> THRIVE </em>’s instructions. Companies affiliated with{' '}
      <em> THRIVE </em> may use the information internationally in connection
      with processing your inquiries, providing its services, and improving our
      products and services. Any data and information that you provide or that
      is otherwise collected on the Website or related sites may be sent
      electronically to a server in another country. <em> THRIVE </em> only
      provides third parties with the minimum amount of personal data necessary
      to provide the services on <em> THRIVE’s </em> behalf, and the third
      parties are not permitted to use your personal data except for the limited
      purpose of completing the requested service or transaction.
    </p>

    <p>
      <em> THRIVE </em> safeguards your privacy interests around the world by
      ensuring that all <em> THRIVE </em> sites adhere to our data protection
      principles described in this Policy. For example, <em> THRIVE </em> may
      store your personal data in the facilities operated by third parties that
      have agreed or must agree to access and use your personal data only for
      the purposes for which they have been engaged by <em> THRIVE </em> and
      they must either:
    </p>

    <ol>
      <li>
        {' '}
        comply with the Privacy Shield principles or another mechanism permitted
        by the applicable EU & Swiss data protection law(s) for transfers and
        processing of personal data; or{' '}
      </li>
      <li>
        {' '}
        agree to provide adequate protections for personal data that are no less
        protective than those set out in this Policy.{' '}
      </li>
    </ol>

    <p>
      {' '}
      <em> THRIVE </em> also may disclose your personal data for other purposes
      or to other third parties if you have consented to or requested such
      disclosure. Please be aware that <em> THRIVE </em> may be required to
      disclose your personal information in response to a lawful request by
      public authorities, including to meet national security or law enforcement
      requirements. <em> THRIVE </em> is liable for appropriate onward transfers
      of your personal data to third parties.
    </p>

    <p>
      {' '}
      <em> THRIVE </em> will not sell or rent your personal data to third
      parties for marketing purposes unless you have granted us permission to do
      so.{' '}
    </p>
    <p>
      <em> THRIVE </em> may respond to subpoenas, court orders, or legal process
      by disclosing your personal data and other related information, if
      necessary.
      <em> THRIVE </em> may choose to establish or exercise our legal rights or
      defend against legal claims.
    </p>

    <p>
      While visiting the Website you may be directed to content or functionality
      that is hosted by a third-party supplier. When information is collected on
      behalf of
      <em> THRIVE </em> exclusively, the appropriate <em> THRIVE </em> affiliate
      privacy statement will be referenced on the site and will govern how
      information is used.
    </p>

    <h3> 11. Inquiries, Access and Updating Information </h3>
    <p>
      <em> THRIVE </em> requests your assistance in maintaining the accuracy of
      the information you have provided to <em> THRIVE </em>. If you are a
      registered user, you are invited to update the information yourself online
      at{' '}
    </p>

    <a href='mailto:contactus@thrivetrm.com/'>contactus@thrivetrm.com </a>

    <p>
      If you are an EU or Swiss citizen about whom <em> THRIVE </em> holds
      personal data on your or its client’s behalf, you may request access to,
      and the opportunity to update, correct or delete, your personal data. To
      submit such requests or raise any other questions, please contact the
      business that either collected or provided your personal data to{' '}
      <em> THRIVE </em>. You can also contact <em> THRIVE’s </em> EU-U.S.
      Privacy Shield Contact (above). In either case,
      <em> THRIVE </em> reserves the right to take appropriate steps to
      authenticate an applicant’s identity, to charge an adequate fee before
      providing access and to deny requests, except as required by the EU-U.S.
      Privacy Shield Framework.
    </p>

    <p>
      If you have any other questions about this privacy statement or if you
      wish to inspect the information we hold about you, please contact:
    </p>
    <a href='mailto:contactus@thrivetrm.com/'>contactus@thrivetrm.com </a>
  </PageContainer>
);

export default pure(PrivacyPage);
