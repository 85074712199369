import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import mapMarkerIcon from '../../images/map-marker-alt.svg';
import getJobListingLocationName from '../../selectors/getJobListingLocationName';

/**
 * Renders a job listing record's location name.
 */
export const JobLocation = ({ locationName }) =>
  locationName ? (
    <span className='JobLocation'>
      {locationName}
      <img
        alt=''
        className='JobLocation__icon'
        height={12}
        src={mapMarkerIcon}
      />
    </span>
  ) : null;

JobLocation.defaultProps = {
  locationName: null,
};

JobLocation.propTypes = {
  locationName: PropTypes.string,
};

/**
 * Connected JobLocation, looks up the location name provided a `jobListingId` value.
 */
export default connect(
  (state, { jobListingId }) => ({
    locationName: getJobListingLocationName(state, jobListingId),
  }),
  null,
)(JobLocation);
