import { useSelector } from 'react-redux';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import { getHostnameFromUrl } from '../../util';

import './AppHeader.scss';

/**
 * The global application header.
 */
const AppHeader = () => {
  const customerLogoUrl = useSelector((state) => state.customer.get('logoUrl'));
  const customerUrl = useSelector((state) =>
    state.customer.get('applyCustomerUrl'),
  );
  const customerUrlTitle = useSelector((state) =>
    state.customer.get('applyCustomerUrlTitle'),
  );
  const customerName = useSelector((state) => state.customer.get('name'));

  return (
    <Navbar className='AppHeader' fixed='top'>
      <div className='u-flex u-flexAlign-c u-flexJustify-spaceBetween'>
        <Navbar.Brand>
          <Link to='/'>
            <img
              alt={`${customerName} logo`}
              className='header--customer-logo'
              height={32}
              src={customerLogoUrl}
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Collapse>
          <Nav>
            {customerUrl ? (
              <Nav.Link
                href={customerUrl}
                rel='noopener noreferrer'
                target='_blank'
              >
                {customerUrlTitle || getHostnameFromUrl(customerUrl)}
              </Nav.Link>
            ) : null}
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default AppHeader;
