import PropTypes from 'prop-types';
import Form from '@thrivetrm/ui/components/Form';
import FormMultiValueInput from 'core/components/Form.MultiValueInput';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import { useSelector } from 'react-redux';
import SelectMenu from '@thrivetrm/ui/components/SelectMenu';
import formDataPropType from '../../propTypes/formDataPropType';
import { formatPrivacyPolicyUrl } from '../../util';

const getSelectOptions = (options) =>
  options
    ?.map((option) => ({
      label: option.get('name'),
      value: option.get('id'),
    }))
    .toArray();

const ApplySelfIdentificationForm = ({
  caslCompanyName,
  caslPrivacyPolicyUrl,
  formData,
  isApplicationLoading,
  onBack,
  onSubmit,
}) => {
  const customer = useSelector((state) => state.customer);

  const applyConsentText = customer.get('applyConsentText');

  const demographicsJustificationMessage = customer.get(
    'demographicsJustificationMessage',
  );

  const genderOptions = getSelectOptions(customer.get('genders'));

  const raceEthnicityOptions = getSelectOptions(
    customer.get('raceEthnicities'),
  );

  const veteranStatusOptions = getSelectOptions(
    customer.get('veteranStatuses'),
  );

  const disabilityStatusOptions = getSelectOptions(
    customer.get('disabilityStatuses'),
  );

  const sexualOrientationOptions = getSelectOptions(
    customer.get('sexualOrientations'),
  );

  const privacyUrl = formatPrivacyPolicyUrl(caslPrivacyPolicyUrl);

  const shouldRenderPrivacyPolicy =
    applyConsentText && caslCompanyName && privacyUrl;

  return (
    <>
      {demographicsJustificationMessage ? (
        <p className='u-fontSize-medium'>{demographicsJustificationMessage}</p>
      ) : null}
      <Form onSubmit={onSubmit}>
        {genderOptions?.length > 0 ? (
          <Form.SelectMenu
            className='u-marginBottom-12'
            data-testid='gender-input'
            initialValue={formData.genderId}
            inputWidth='full'
            name='genderId'
            placeholder='Gender (Optional)'
            size='medium'
          >
            {genderOptions.map((gender) => (
              <SelectMenu.Item key={gender.value} value={gender.value}>
                {gender.label}
              </SelectMenu.Item>
            ))}
          </Form.SelectMenu>
        ) : null}

        {raceEthnicityOptions?.length > 0 ? (
          <FormMultiValueInput
            canUseCustomOptions={false}
            className='u-marginBottom-12'
            data-testid='race-ethnicity-input'
            initialValue={formData.raceEthnicityIds}
            name='raceEthnicityIds'
            options={raceEthnicityOptions}
            placeholder='Race-Ethnicity (Optional)'
          />
        ) : null}

        {veteranStatusOptions?.length > 0 ? (
          <Form.SelectMenu
            className='u-marginBottom-12'
            data-testid='veteran-status-input'
            initialValue={formData.veteranStatusId}
            inputWidth='full'
            name='veteranStatusId'
            placeholder='Veteran Status (Optional)'
            size='medium'
          >
            {veteranStatusOptions.map((veteranStatus) => (
              <SelectMenu.Item
                key={veteranStatus.value}
                value={veteranStatus.value}
              >
                {veteranStatus.label}
              </SelectMenu.Item>
            ))}
          </Form.SelectMenu>
        ) : null}

        {disabilityStatusOptions?.length > 0 ? (
          <Form.SelectMenu
            className='u-marginBottom-12'
            data-testid='disability-status-input'
            initialValue={formData.disabilityStatusId}
            inputWidth='full'
            name='disabilityStatusId'
            placeholder='Disability Status (Optional)'
            size='medium'
          >
            {disabilityStatusOptions.map((disabilityStatus) => (
              <SelectMenu.Item
                key={disabilityStatus.value}
                value={disabilityStatus.value}
              >
                {disabilityStatus.label}
              </SelectMenu.Item>
            ))}
          </Form.SelectMenu>
        ) : null}

        {sexualOrientationOptions?.length > 0 ? (
          <Form.SelectMenu
            className='u-marginBottom-12'
            data-testid='sexual-orientation-input'
            initialValue={formData.sexualOrientationId}
            inputWidth='full'
            name='sexualOrientationId'
            placeholder='Sexual Orientation (Optional)'
            size='medium'
          >
            {sexualOrientationOptions.map((sexualOrientation) => (
              <SelectMenu.Item
                key={sexualOrientation.value}
                value={sexualOrientation.value}
              >
                {sexualOrientation.label}
              </SelectMenu.Item>
            ))}
          </Form.SelectMenu>
        ) : null}

        {shouldRenderPrivacyPolicy ? (
          <div className='u-marginTop-16'>
            <a href={privacyUrl} rel='noopener noreferrer' target='_blank'>
              View Privacy Policy for {caslCompanyName}
            </a>
          </div>
        ) : null}

        <div className='u-flex u-flexJustify-r'>
          <ButtonPrimary isOutline={true} label='Back' onClick={onBack} />
          <Form.SubmitButton
            isDisabled={isApplicationLoading}
            isLoading={isApplicationLoading}
            label='Submit'
          />
        </div>
      </Form>
    </>
  );
};

ApplySelfIdentificationForm.defaultProps = {
  caslCompanyName: null,
  caslPrivacyPolicyUrl: null,
  formData: null,
};

ApplySelfIdentificationForm.propTypes = {
  caslCompanyName: PropTypes.string,
  caslPrivacyPolicyUrl: PropTypes.string,
  formData: formDataPropType,
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ApplySelfIdentificationForm;
