const MIME_TYPE_JSON = 'application/json';
const NO_CONTENT_STATUS_CODE = 204;

export default ({ options, url }) =>
  fetch(url, options).then((response) => {
    const responseContentType = response.headers.get('content-type');
    const isJson =
      responseContentType && responseContentType.includes(MIME_TYPE_JSON);

    if (response.ok) {
      if (
        response.status === NO_CONTENT_STATUS_CODE ||
        response.headers['Content-Length'] === 0
      ) {
        // No content, so can't parse json, just return null
        return Promise.resolve(null);
      }

      return isJson ? response.json() : response.text();
    }

    // We may have gotten a JSON response with some
    // error information, so still try to parse it so we can decorate
    // our error with some additional information.
    return (
      (isJson ? response.json() : response.text())
        // Ignore parsing errors
        .catch(() => null)
        .then((body) => {
          const error = new Error(response.statusText);
          error.status = response.status;
          error.response = response;
          error.body = body;
          throw error;
        })
    );
  });
