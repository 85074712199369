import { connect } from 'react-redux';
import getJobListingLocationName from '../../selectors/getJobListingLocationName';

/**
 * A higher order component that filters a list of jobListingIds based on an array of
 * location filter values (provided by a `locationFilters` prop).
 */
export default connect((state, { jobListingIds, locationFilters }) => {
  if (jobListingIds && locationFilters && locationFilters.length) {
    return {
      jobListingIds: jobListingIds.filter((id) =>
        locationFilters.includes(getJobListingLocationName(state, id)),
      ),
    };
  }

  return {};
}, null);
