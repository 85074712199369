import { compose, withHandlers, withProps } from 'recompose';
import queryString from 'query-string';
import {
  QUERY_STRING_PARAM_KEYWORD,
  QUERY_STRING_PARAM_LOCATION,
  QUERY_STRING_PARAM_JOB_FUNCTION,
  QUERY_STRING_PARAM_PLACEMENT_TYPE,
} from '../../../constants';

export default compose(
  withProps(({ location }) => {
    const query = queryString.parse((location && location.search) || '');
    const keywordFilter = query[QUERY_STRING_PARAM_KEYWORD] || '';
    const locationFilters = query[QUERY_STRING_PARAM_LOCATION]
      ? [].concat(query[QUERY_STRING_PARAM_LOCATION])
      : [];
    const functionNameFilters = query[QUERY_STRING_PARAM_JOB_FUNCTION]
      ? [].concat(query[QUERY_STRING_PARAM_JOB_FUNCTION])
      : [];
    const placementTypeFilters = query[QUERY_STRING_PARAM_PLACEMENT_TYPE]
      ? [].concat(query[QUERY_STRING_PARAM_PLACEMENT_TYPE])
      : [];

    return {
      keywordFilter: keywordFilter,
      locationFilters: locationFilters,
      functionNameFilters: functionNameFilters,
      placementTypeFilters: placementTypeFilters,
      isFiltered: Boolean(
        keywordFilter ||
          locationFilters.length ||
          functionNameFilters.length ||
          placementTypeFilters.length,
      ),
    };
  }),
  withHandlers({
    setQueryParam:
      ({ location, navigate }) =>
      (key, value) => {
        const query = queryString.parse(location.search);
        const isEmpty =
          !value ||
          (typeof value === 'string' && !value.trim()) ||
          (Array.isArray(value) && value.length === 0);

        if (isEmpty) {
          delete query[key];
        } else {
          query[key] = value;
        }

        const search = queryString.stringify(query);

        // If the current URL has no search, push the new query as a new URL, otherwise replace
        // the current querystring.
        navigate(`${location.pathname}?${search}`, {
          replace: Boolean(location.search),
        });
      },
  }),
  withHandlers({
    setKeywordFilter:
      ({ setQueryParam }) =>
      (value) =>
        setQueryParam(QUERY_STRING_PARAM_KEYWORD, value),
    setLocationFilters:
      ({ setQueryParam }) =>
      (value) =>
        setQueryParam(QUERY_STRING_PARAM_LOCATION, value),
    setFunctionNameFilters:
      ({ setQueryParam }) =>
      (value) =>
        setQueryParam(QUERY_STRING_PARAM_JOB_FUNCTION, value),
    setPlacementTypeFilters:
      ({ setQueryParam }) =>
      (value) =>
        setQueryParam(QUERY_STRING_PARAM_PLACEMENT_TYPE, value),
    clearFilters:
      ({ location, navigate }) =>
      () => {
        if (location.search) {
          navigate(`${location.pathname}`, { replace: true });
        }
      },
  }),
);
