/* eslint-disable filenames/match-exported */
// ^ This component is a subcomponent so it uses a `Form.Subcomponent` filename
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import ButtonLink from '@thrivetrm/ui/components/ButtonLink';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import useFormInput from '@thrivetrm/ui/hooks/useFormInput';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import formValidationRulesPropType from '../../propTypes/formValidationRulesPropType';

import './Form.Dropzone.scss';

const FormDropzone = ({ initialValue, label, name, rules, ...props }) => {
  const { formattedLabel, onChange, value } = useFormInput({
    name: name,
    initialValue: initialValue,
    label: label,
    rules: rules,
  });

  const { getInputProps, getRootProps, open } = useDropzone({
    multiple: false,
    noClick: true,
    onDropAccepted: (files) => onChange(files[0]),
  });

  const inputId = uniqueId('input');

  return (
    <div {...props} className='FormDropzone'>
      <label htmlFor={inputId}>{formattedLabel}</label>
      <div
        {...getRootProps({
          className: 'FormDropzone__dropBox',
        })}
      >
        <input {...getInputProps({ id: inputId })} />
        {value ? (
          <div>
            <span className='u-wordBreak'>{value.name}</span>
            <ButtonSecondary
              className='u-marginLeft-12'
              icon='delete'
              onClick={() => onChange(null)}
              size='small'
            />
          </div>
        ) : (
          <span>
            <ButtonLink label='Choose a document' onClick={open} size='small' />
            <span> or drag & drop here.</span>
          </span>
        )}
      </div>
    </div>
  );
};

FormDropzone.defaultProps = {
  initialValue: null,
  label: null,
  rules: null,
};

FormDropzone.propTypes = {
  initialValue: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  rules: formValidationRulesPropType,
};

export default FormDropzone;
