import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import ButtonLink from '../../buttons/ButtonLink';
import ApplyButton from '../../applications/ApplyButton';
import JobLocation from '../../jobListings/JobLocation';
import JobPlacementType from '../../jobListings/JobPlacementType';
import JobPostedDate from '../../jobListings/JobPostedDate';
import RelatedJobsLink from '../../jobListings/RelatedJobsLink';
import Icon from '@thrivetrm/ui/components/Icon';
import CollapseContent from '../CollapseContent';

import './JobPageSidebar.scss';

/**
 * Renders the sidebar of the JobPage.
 */
export const JobPageSidebar = ({ jobListing }) => (
  <div className='JobPageSidebar'>
    <ButtonLink block={true} className='JobPageSidebar__back-button' to='/'>
      <span className='sr-only'>Job listing Page </span>
      <Icon color='blue' type='chevronLeft' /> Job listings
    </ButtonLink>
    {jobListing && jobListing.has('id') && (
      <div>
        <h3 className='JobPageSidebar__section'>
          <JobLocation jobListingId={jobListing.get('id')} />
          <JobPlacementType jobListingId={jobListing.get('id')} />
        </h3>

        <CollapseContent className='JobPageSidebar__section'>
          <JobPostedDate date={jobListing.get('publishedDate')} />
        </CollapseContent>

        <div className='JobPageSidebar__section'>
          <ApplyButton jobListingId={jobListing.get('id')} />
        </div>

        {jobListing.get('jobFunctionName') && (
          <CollapseContent className='JobPageSidebar__section'>
            <RelatedJobsLink jobListing={jobListing} />
          </CollapseContent>
        )}
      </div>
    )}
  </div>
);

JobPageSidebar.propTypes = {
  jobListing: ImmutablePropTypes.mapContains({
    id: PropTypes.number,
    jobTitle: PropTypes.string,
    jobFunctionName: PropTypes.string,
    placementTypeName: PropTypes.string,
  }),
};

export default JobPageSidebar;
