import selectn from 'selectn';
import { Map, List } from 'immutable';

import composeReducers from '../composeReducers';
import jobListingsReducer from './jobListingsReducer';
import { jobListing as jobListingSchema } from '../../schemas';

export function entitiesMerger(oldVal, newVal) {
  if (
    oldVal &&
    oldVal.mergeWith &&
    !List.isList(oldVal) &&
    !List.isList(newVal)
  ) {
    return oldVal.mergeWith(entitiesMerger, newVal);
  }

  return newVal;
}

const entitiesReducer = (state = new Map(), action) => {
  const entities = selectn('payload.entities', action);

  if (entities) {
    return state.mergeWith(entitiesMerger, entities);
  }

  return state;
};

export default composeReducers(entitiesReducer, (state, action) =>
  state.update(jobListingSchema.key, (jobListingsState) =>
    jobListingsReducer(jobListingsState, action),
  ),
);
