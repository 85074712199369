import url from 'url';
import JobShareLink from './JobShareLink';

export const createFacebookShareLink = (jobListing, jobUrl) =>
  url.format({
    protocol: 'https',
    host: 'www.facebook.com',
    pathname: '/sharer/sharer.php',
    query: {
      u: jobUrl,
      picture: '',
      title: `${jobListing.get('jobTitle')} opportunity in ${jobListing.get(
        'location',
      )}`,
      caption: 'Thrive Apply',
      quote: '',
      description: '',
    },
  });

const JobShareFacebookLink = ({ jobListing, jobUrl }) => (
  <JobShareLink
    href={createFacebookShareLink(jobListing, jobUrl)}
    icon='facebook'
  />
);

export default JobShareFacebookLink;
