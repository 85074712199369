import classnames from 'classnames';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';

import './ButtonLink.scss';

const ButtonLink = ({ className, to, ...props }) => {
  const navigate = useNavigate();

  return (
    <Button
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className={classnames(className, 'ButtonLink')}
      href={to}
      onClick={(event) => {
        event.preventDefault();
        navigate(to);
      }}
      variant='link'
    />
  );
};

ButtonLink.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
};

export default ButtonLink;
