/* eslint-disable filenames/match-exported */
// ^ This component is a subcomponent so it uses a `Form.Subcomponent` filename
import PropTypes from 'prop-types';
import formValidationRulesPropType from '../../propTypes/formValidationRulesPropType';
import MultiValueInput from './MultiValueInput';
import useFormInput from '@thrivetrm/ui/hooks/useFormInput';

const FormMultiValueInput = ({
  initialValue,
  label,
  name,
  rules,
  ...props
}) => {
  const { errorMessage, formattedLabel, onBlur, onChange, value } =
    useFormInput({
      name: name,
      initialValue: initialValue,
      label: label,
      rules: rules,
    });

  return (
    <MultiValueInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      errorMessage={errorMessage}
      label={formattedLabel}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
    />
  );
};

FormMultiValueInput.defaultProps = {
  initialValue: null,
  label: null,
  rules: null,
};

FormMultiValueInput.propTypes = {
  initialValue: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ),
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  rules: formValidationRulesPropType,
};

export default FormMultiValueInput;
