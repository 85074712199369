/* eslint-disable react/no-unescaped-entities */
import { pure } from 'recompose';
import { Helmet } from 'react-helmet';
import PageContainer from '../PageContainer';

const TermsAndConditionsPage = () => (
  <PageContainer fixedWidth={true}>
    <Helmet>
      <title>Usage</title>
    </Helmet>
    <h2 className='text-center'> TERMS AND CONDITIONS OF USE </h2>
    <p className='text-center'>
      <em> Last revised on December 5, 2016</em>
    </p>
    <p>
      Please read the Terms and Conditions of Use AND PRIVACY POLICY (
      <em> "Terms of Use" </em>) before using this Website. (
      <em> "Website" </em>
    </p>

    <p>
      Your access to and use of this Website is subject to, and constitutes your
      acceptance of, the Terms of Use posted on this Website, as may be modified
      by <em> Thrive TRM LLC ("THRIVE") </em>
      from time to time without notice to you, as well as applicable laws of the
      Commonwealth of Pennsylvania without regard to its conflicts of law
      provisions. If you do not agree to the Terms of Use, then exit the Website
      on your browser. If you remain on this Website, or return thereafter, you
      agree to be bound by the Terms of Use.
    </p>

    <p>
      You agree to use the Website for lawful purposes only. The information
      posted publicly on this Website is general in nature and any information
      provided through secured, registered access to the Website will be
      maintained as confidential information (collectively <em> "Content" </em>{' '}
      ) CONTENT RELATED TO THE WEBSITE IS NOT INTENDED TO BE RELIED UPON FOR
      PERSONAL, FINANCIAL, OR LEGAL DECISIONS AND YOU SHOULD CONSULT AN
      APPROPRIATE PROFESSIONAL FOR SPECIFIC ADVICE TAILORED TO YOUR SITUATION.
      Certain products and services offered through the Website ({' '}
      <em> "Services" </em> ) , particularly through secured, registered access
      to the Website, may include implementation, customization, or other
      investigative search tools provided by one or more third parties (
      <em> "Tools" </em> ) relating to your use of the Website or the Services.
      Tools can include services that collect or exchange data or provide
      additional functionality within the user interface provided through the
      Website. <em> THRIVE </em> does not warrant any such third party Tools or
      third party providers of the Tools, whether such Tools are designated by{' '}
      <em> THRIVE </em> as “authorized”, “approved”, or the like. Additionally,
      from time to time, additional functionality may be offered by
      <em> THIRVE </em> to you on a pass-through or OEM basis pursuant to the
      terms specified by a third party licensor and agreed to by you in
      connection with a separate agreement and/or purchase by you of such
      additional functionality (<em> "Additional Functionality" </em> ). Your
      use of such Additional Functionality shall be governed by such terms or by
      other applicable terms and conditions, which shall prevail in the event of
      any inconsistency with the terms of the Agreement.
    </p>

    <p>
      YOUR USE OF THE WEBSITE, ITS CONTENT, AND THE SERVICES IS AT YOUR SOLE
      RISK. <em> THRIVE </em> makes no representation or warranty that the
      Website, its Content, and/or the Services will operate without
      interruption or error, nor does it provide any assurances of the
      availability or functionality of the Website, its Content, or the
      Services. THE WEBSITE, ITS CONTENT AND THE SERVICES ARE PROVIDED ON AN “AS
      IS" AND AN “AS AVAILABLE" BASIS. THE WEBSITE, ITS CONTENT AND ITS SERVICES
      IS PROVIDED WITHOUT ANY REPRESENTATIONS, ENDORSEMENTS, OR WARRANTIES OF
      ANY KIND WHATSOEVER, EITHER EXPRESS OR IMPLIED INCLUDING BUT NOT LIMITED
      TO ANY WARRANTIES OF TITLE OR ACCURACY AND ANY IMPLIED WARRANTIES OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.{' '}
      <em> THRIVE </em> MAKES NO REPRESENTATIONS, ENDORSEMENTS, OR WARRANTIES,
      EITHER EXPRESS OR IMPLIED, WITH RESPECT TO ANY WEBSITE OPERATED BY A THIRD
      PARTY. <em> THRIVE </em> also makes no representation, endorsement, or
      warranty that any Content or link provided with this Website is accurate
      or suitable for any particular purpose. Finally, without limiting the
      foregoing, <em> THRIVE </em>does not endorse, guarantee, represent,
      warrant, or recommend any third party product and/or service, nor the
      accuracy, completeness, or usefulness thereof.
    </p>

    <p>
      You agree not to take any action that might compromise the security of
      this Website, render this Website inaccessible to others or otherwise
      cause damage to this Website, its Content, and/or the Service (whether
      public or secured as confidential) contained on this Website. Any conduct
      by you that, in <em> THRIVE's </em> sole discretion, restricts, inhibits,
      or interferes with the ability of any other user to enjoy this Website is
      prohibited including by means of hacking or defacing any portion of this
      Website, or by engaging in spamming, flooding, or other disruptive
      activities. You are strictly prohibited from communicating on or through
      the Website, or using the Services, in any unlawful, harmful, offensive,
      threatening, libelous, harassing, defamatory, obscene, fraudulent, or
      objectionable material of any sort including, but not limited to, any
      material that encourages conduct that would constitute a criminal offense,
      give rise to civil liability, or otherwise violate any applicable local,
      state, national, or international law. <em> THRIVE </em> reserves the
      right to terminate or suspend your access to and use of this Website
      without notice, if <em> THRIVE </em> believes, in its sole discretion,
      that you are in violation of any applicable law or it is harmful to the
      interests including intellectual property or other right of
      <em> THRIVE </em> or other user or third party.
    </p>

    <h3> INTELLECTUAL PROPERTY </h3>

    <p>
      Unless otherwise expressed in the Terms of Use, the Content of this
      Website including the graphics, photographs, videos, text, logos and
      overall appearance of the Website are the sole and exclusive property of{' '}
      <em> THRIVE </em>. Content is protected by United States and international
      copyright laws. You agree not to delete any copyright, service mark,
      trademark or similar notice from any Content obtained from the Website.
      All rights reserved.
    </p>

    <p>
      <em>THRIVE TRM sup SM</em>, <em>THRIVE sup SM</em>,{' '}
      <em>Blue Arrow Logo sup SM </em>, and all other graphics, logos, page
      headers, button icons, scripts, and service names are trademarks and/or
      service marks, registered trademarks and/or service marks, or trade dress
      (collectively “Marks”) of <em> THRIVE </em>in the U.S. and/or other
      countries. The Marks, whether or not registered, may not be used with any
      product or service in any manner that is likely to cause confusion among
      customers, or in any manner that disparages or discredits{' '}
      <em> THRIVE </em>. All other trademarks and/or service marks displayed on
      the Website are the property of their respective owners.
    </p>

    <p>
      Use of any of the Marks as metatags on other websites is strictly
      prohibited. The use or misuse of the Marks or any other content on this
      Website, except as provided in these Terms of Use or in the Content of the
      Website, is strictly prohibited. Copying, downloading, reproducing,
      modifying, publishing, distributing, transmitting, transferring or
      creating derivative works from the Content of the Website without the
      prior written consent of <em> THRIVE </em> is strictly prohibited.
      <em> THRIVE </em> hereby grants permission to you to download, print and
      store selected portions of the Content for your own personal,
      non-commercial use. You cannot copy or post the Content on any network
      computer or broadcast the Content in any media, and you cannot modify or
      alter the Content in any way, or delete or change any legal notice. No
      right, title or interest in any downloaded materials is transferred to you
      as a result of any such downloading. <em> THRIVE </em> reserves all title
      in the intellectual property rights in any Content you download from the
      Website.
    </p>

    <h3> REGISTERED USER RESPONSIBILITY </h3>

    <p>
      If you are a registered user of the Website, you are responsible for
      maintaining the confidentiality of any passwords given to you and to
      report to
      <em> THRIVE </em> immediately if the password is lost or stolen; there is
      any change in authorization or access for use of the password; or if your
      registered authorizations are revoked. User IDs and passwords are provided
      to you personally, and you agree that you will not give your password to
      any other party. You have no right to, and you specifically agree not to:
      (a) attempt to transfer, assign or sublicense any rights you receive under
      the Terms of Use to any other person; (b) make error corrections to or
      otherwise modify or adapt the Website or create derivative works of the
      Website, or to permit third parties to do the same; or (c) decompile,
      decrypt, reverse engineer, disassemble or otherwise reduce any portion of
      the Website to human-readable form to gain access to Confidential
      Information through the Website. Any purported transfer, assignment or
      sublicense by you of the limited and revocable rights granted to you under
      these terms and conditions shall be void.
    </p>

    <h3> CONFIDENTIAL INFORMATION </h3>
    <p>
      You agree to hold any Confidential Information that you obtain by using
      the Website and/or the Services in confidence and, unless required by law,
      not to make the Confidential Information available to any third party or
      to use the Confidential Information for any purpose other than the
      business activities for which you were given access to the Confidential
      Information. You agree to take all reasonable steps to ensure that
      Confidential Information is not disclosed or distributed by you or your
      employees or agents in violation of the Terms of Use. For purposes of the
      Terms of Use, the phrase <em> "Confidential Information" </em> means any
      and all proprietary information or information which a reasonable person
      would know constitutes proprietary information, including but not limited
      to, business plans and strategies, financial information, user Ids and
      passwords, customer lists, information about employee or executive
      candidates, company hiring practices, and other company and/or candidate
      information. You agree that “Confidential Information” includes aspects of
      the Website and/or the Services including the specific design and
      structure of individual programs and functionalities. You shall not
      disclose, provide, or otherwise make available any Confidential
      Information or copyrighted material owned by <em> THRIVE </em>
      in any form to any third party without the prior written consent of{' '}
      <em> THRIVE. </em> You shall implement reasonable security measures to
      protect such trade secrets and copyrighted material.
    </p>

    <h3> LIMITATION ON LIABILITY </h3>
    <p>
      <em> THRIVE </em>does not accept any liability for the consequences
      arising from the access, use, or misuse of the Website, its Content and/or
      the Services.
    </p>

    <p>
      TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL{' '}
      <em> THRIVE </em> or ITS affiliates, officers, directors, MANAGERS,
      agents, contractors OR employees BE LIABLE FOR ANY DAMAGES INCLUDING
      WITHOUT LIMITATION DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
      PUNITIVE DAMAGES, WHETHER UNDER A CONTRACT, TORT OR ANY OTHER THEORY OF
      LIABILITY, ARISING IN CONNECTION WITH YOUR USE OF OR ACCESS TO THE
      WEBSITE, YOUR USE OF SERVICES AND/OR FUNCTIONALITIES PROVIDED BY{' '}
      <em> THRIVE </em> OR ANY THIRD PARTY THROUGH THE WEBSITE OR OTHERWISE, OR
      IN CONNECTION WITH ANY FAILURE OF PERFORMANCE, WHETHER THE INFORMATION ON
      THIS WEBSITE IS ACCURATE, COMPLETE, RELIABLE, USEFUL, TIMELY OR CURRENT,
      OR CONTAINS AN ERROR OR OMISSION, WHETHER THE WEBSITE OR RELATED SERVICES
      IS INTERRUPTED, DEFECTIVE, DELAYED IN OPERATION OR TRANSMISSION, CONTAINS
      A COMPUTER VIRUS, A LINE SYSTEM FAILURE, OR CAUSES A LOSS OF DATA, OR LOSS
      OF USE RELATED TO THIS WEBSITE OR ANY WEBSITE OPERATED BY ANY THIRD PARTY
      OR ANY CONTENTS OF THIS WEBSITE OR ANY OTHER WEBSITE, EVEN IF{' '}
      <em> THRIVE </em> IS AWARE OF THE POSSIBILITY OF SUCH DAMAGES.{' '}
      <em> THRIVE </em> is not liable for the unauthorized access to or use of
      the Content or the Services.
    </p>

    <h3> INDEMNITY </h3>
    <p>
      You hereby agree to indemnify and hold <em> THRIVE </em>, and its
      affiliates, officers, directors, managers, agents, contractors, and
      employees, harmless from any claim or demand, including reasonable
      attorney's fees, made by any third party due to or arising out of your
      access to and use of the Website, its Content, the Services, or any
      information that you submit, post, transmit or receive through this
      Website, your connection to the Website, your violation of the Terms of
      Use, or your violation of any rights of a third party.
    </p>

    <h3> ELECTRONIC COMMUNICATIONS </h3>
    <p>
      All electronic communications in connection with the Website are subject
      to the Privacy Policy. Such transmissions can be intercepted by third
      parties. Please do not send to <em> THRIVE </em> any email communication
      that contains confidential information.
    </p>

    <h3> Links to Third Party Sites </h3>

    <p>
      <em> THRIVE </em>may establish on the Website links to third party
      websites. <em>THRIVE </em> does not control such websites and is not
      responsible for the content provided thereon.
      <em> THRIVE </em> may add or remove any link at any time from the Website.
      You agree that <em> THRIVE </em> is not responsible for any and all third
      party information, Tools, or Additional Functionality that may be provided
      to you through the Website.
    </p>

    <h3> JURISDICTION </h3>
    <p>
      In the event of any dispute arising out of or relating to your access and
      use of this Website, its Content, or the Services, any dispute shall be in
      state or federal court located in the Eastern District of Pennsylvania.
      You hereby agree to personal jurisdiction by such courts and to waive any
      jurisdictional, venue, or inconvenient forum objections to such courts.
      Any failure on <em> THRIVE's </em> part to insist upon strict performance
      of any provision of the Terms of Use shall not be construed as a waiver of
      any provision or right.
    </p>

    <p>
      If any part of the Terms of Use is determined by a court to be
      unenforceable, then such part shall be limited or eliminated to the
      minimum extent necessary with the Terms of Use remaining in full force and
      legal effect. The Terms of Use constitute the entire agreement governing
      your access to and use of the Website, its Content, and the Services.
    </p>

    <p>All rights not expressly granted by the Terms of Use are reserved.</p>

    <h3> CONTACT INFORMATION </h3>
    <p>
      If you have any questions or comments about these Terms of Use, please
      contact <em> THRIVE</em> at:{' '}
      <a href='mailto:contactus@thrivetrm.com/'>contactus@thrivetrm.com </a>
    </p>
  </PageContainer>
);

export default pure(TermsAndConditionsPage);
