import { createSelector } from 'reselect';
import getJobListing from './getJobListing';

/**
 * Gets a value indicating whether -- given the current application state -- a particular job
 * listings should be fetched from the server.
 * @param {Object} state the application state
 * @param {String|Number} jobListingId the ID for the job listing.
 * @return {Boolean} True if all job listing should to be fetched from the server; otherwise, false.
 */
export default createSelector(getJobListing, (jobListing) => {
  if (!jobListing) {
    return true;
  }

  if (jobListing.get('isFetching')) {
    return false;
  }

  if (jobListing.has('error')) {
    return false;
  }

  return Boolean(jobListing.get('isInvalidated'));
});
