import PropTypes from 'prop-types';

/**
 * Renders a job listing record's description.
 */
export const JobDescription = ({ description }) => (
  <div
    className='JobDescription'
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{ __html: description }}
  />
);

JobDescription.propTypes = {
  /**
   * The job description
   */
  description: PropTypes.string,
};

/**
 * Connected JobDescription, looks up the location name provided a `jobListingId` value.
 */
export default JobDescription;
