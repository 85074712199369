import escapeStringRegex from 'escape-string-regexp';

/**
 * An identity function that returns the first argument given.
 */
export const identity = (arg) => arg;

/**
 * A function that, when called with an event object, calls preventDefault on the event.
 * @param {Event} e A browser event.
 */
export const preventDefaultEvent = (e) =>
  e && e.preventDefault && e.preventDefault();

/**
 * A regular expression that splits on whitespace.
 */
export const WHITESPACE_REGEX = /\s+/;

/**
 * Creates a regular expression that can be used to determine if another string contains all
 * of the words of the input string, where order does not matter.
 * @param {String} input The string of keywords.
 * @return {RegExp} The RegExp that can be used for testing the existense of all keywords in the
 *   input string.
 */
export const createKeywordsRegex = (input) => {
  const expression = input
    // Remove leading/trailing whitespace
    .trim()
    // Create each term by splitting on white space.
    .split(WHITESPACE_REGEX)
    // Generate a regex to match each term
    .map((term) => `(?=.*${escapeStringRegex(term)})`)
    // Join all regexes into a single regex expression.
    .join('');

  // Finally, create a new RegExp object using the expressions we generated, and ensure we're
  // doing case insensitive matching.
  return new RegExp(expression, 'i');
};

let anchorElement;

/**
 * Parse a URL string and extract a part of it.
 * @param {String} url The URL to parse
 * @param {String} part The part of the URL to extract. Can be any property of an
 *   `HTMLAnchorElement` (@see {https://developer.mozilla.org/en-US/docs/Web/API/HTMLAnchorElement})
 * @return {String} The requested part of the URL, or the original URL if parsing is not supported.
 */
export const extractUrlPart = (url, part) => {
  if (typeof document === 'undefined' || !document.createElement) {
    return url;
  }

  if (!anchorElement) {
    anchorElement = document.createElement('a');
  }

  anchorElement.href = url;
  return anchorElement[part];
};

/**
 * Returns an absolute URL for any URL. If the incoming URL is relative, the current site will
 * be used to complete it.
 * @param {String} url a URL
 * @return {String} an absolute version of the URL.
 */
export const toAbsoluteUrl = (url) => extractUrlPart(url, 'href');

/**
 * Returns the hostname part of a URL.
 * @param {String} url a URL
 * @return {String} The hostname portion of the URL
 */
export const getHostnameFromUrl = (url) => extractUrlPart(url, 'hostname');

/**
 * Returns a formatted privacy policy URL
 * A URL like '//www.example.com' is valid and will use the host scheme
 * @param {String} url a URL
 * @returns {String}
 */
export const formatPrivacyPolicyUrl = (url) =>
  url && !url.startsWith('//') && !url.includes('://') ? `https://${url}` : url;
