import PropTypes from 'prop-types';
import { format } from 'timeago.js';

/**
 * Renders a relative time string (relative to the current time).
 */
const TimeAgo = ({ value }) => <time dateTime={value}>{format(value)}</time>;

TimeAgo.defaultProps = {
  value: null,
};

TimeAgo.propTypes = {
  /** ISO8601-formatted time string */
  value: PropTypes.string,
};

export default TimeAgo;
