import { jobListing as jobListingSchema } from '../schemas';

/**
 * Gets the state of a specific job listing.
 * @param {Object} state the application state
 * @param {String|Number} jobListingId the ID for the job listing.
 * @return {Map} the state for the job listing with the jobListingId provided, if found.
 */
export default (state, jobListingId) =>
  state.entities.getIn([jobListingSchema.key, String(jobListingId)]);
