import queryString from 'query-string';
import { Link } from 'react-router-dom';
import Icon from '@thrivetrm/ui/components/Icon';
import { QUERY_STRING_PARAM_JOB_FUNCTION } from '../../constants';

const RelatedJobsLink = ({ jobListing }) => (
  <Link
    to={`/?${queryString.stringify({
      [QUERY_STRING_PARAM_JOB_FUNCTION]: jobListing.get('jobFunctionName'),
    })}`}
  >
    <Icon aria-hidden='true' color='blue' type='view' /> View Related Jobs
  </Link>
);

export default RelatedJobsLink;
