import selectn from 'selectn';
import { Map } from 'immutable';
import { camelizeKeys } from 'humps';
import {
  APPLICANT_SUBMIT_START,
  APPLICANT_SUBMIT_SUCCESS,
  APPLICANT_SUBMIT_FAILURE,
  APPLICANT_STORE,
} from '../actions/actionTypes';
import { LAST_APPLICATION } from '../constants';

/**
 * Applications keyed by jobListingId
 * @param {Map} [state=new Map()] The incoming state
 * @param {Object} action The action to apply to the state
 * @return {Map()} The updated state after applying the action
 */
const applicationSubmissionsReducer = (state = new Map(), action) => {
  switch (action.type) {
    case APPLICANT_STORE: {
      const { applicant, jobListingId } = action.payload;

      return state.withMutations((map) => {
        if (jobListingId) {
          map.mergeDeep({
            [jobListingId]: {
              fields: applicant,
            },
          });
        }

        map.set(LAST_APPLICATION, applicant);
      });
    }
    case APPLICANT_SUBMIT_START: {
      const { applicant, jobListingId } = action.payload;

      return state
        .merge({
          [jobListingId]: {
            isSubmitting: true,
            fields: applicant,
          },
        })
        .set(LAST_APPLICATION, applicant);
    }
    case APPLICANT_SUBMIT_SUCCESS: {
      const { jobListingId } = action.payload;
      const applicationId = selectn('payload.result.jobApplication', action);

      return state.updateIn([String(jobListingId)], (submission) =>
        submission
          .merge({
            isSubmitting: false,
            applicationId: applicationId,
          })
          .delete('error')
          .delete('fieldErrors'),
      );
    }
    case APPLICANT_SUBMIT_FAILURE: {
      const { error, jobListingId } = action.payload;
      const fieldErrors = camelizeKeys(selectn('body.errors', error));

      return state.mergeDeep({
        [jobListingId]: {
          isSubmitting: false,
          error: error,
          fieldErrors: fieldErrors,
        },
      });
    }
    default: {
      return state;
    }
  }
};

export default applicationSubmissionsReducer;
