import { normalize } from 'normalizr';
import { camelizeKeys } from 'humps';
import { jobListing as jobListingSchema } from '../schemas';

import fetchAjax from './fetchAjax';
import {
  JOB_LISTING_FETCH_START,
  JOB_LISTING_FETCH_SUCCESS,
  JOB_LISTING_FETCH_FAILURE,
} from './actionTypes';

const fetchJobListing =
  ({ jobListingId }) =>
  (dispatch) => {
    dispatch({
      type: JOB_LISTING_FETCH_START,
      payload: { jobListingId: jobListingId },
    });

    fetchAjax({ url: `/api/v1/job_listings/${jobListingId}` })
      .then((data) => ({
        type: JOB_LISTING_FETCH_SUCCESS,
        payload: {
          jobListingId: jobListingId,
          ...normalize(camelizeKeys(data), { jobListing: jobListingSchema }),
        },
      }))
      .catch((error) => ({
        type: JOB_LISTING_FETCH_FAILURE,
        payload: {
          jobListingId: jobListingId,
          error: error,
        },
      }))
      .then(dispatch);
  };

export default fetchJobListing;
