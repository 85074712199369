/* eslint-disable react/jsx-sort-props */
// ^ it's much easier to read all of the 'path' props first
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import JobPage from '../pages/job/JobPage';
import JobListingsPageRouteDataWrapper from '../pages/jobListings/JobListingsPageRouteDataWrapper';
import PrivacyPage from '../pages/privacy/PrivacyPage';
import TermsPage from '../pages/terms/TermsPage';
import NotFoundPage from '../pages/notFound/NotFoundPage';
import AppHeader from '../header/AppHeader';

const Customer = () => (
  <BrowserRouter>
    <AppHeader />
    <Routes>
      <Route path='/' element={<JobListingsPageRouteDataWrapper />} />
      <Route path='/job/:jobListingId' element={<JobPage />}>
        <Route path=':action' element={<JobPage />}>
          <Route path=':apply_form' element={<JobPage />} />
        </Route>
      </Route>
      <Route path='/privacy' element={<PrivacyPage />} />
      <Route path='/terms' element={<TermsPage />} />
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  </BrowserRouter>
);

export default Customer;
