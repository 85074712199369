import { combineReducers } from 'redux';
import allJobListingsReducer from './allJobListingsReducer';
import appReducer from './appReducer';
import applicationSubmissionsReducer from './applicationSubmissionsReducer';
import customerReducer from './customerReducer';
import entities from './entities';

const rootReducer = combineReducers({
  allJobListings: allJobListingsReducer,
  app: appReducer,
  applicationSubmissions: applicationSubmissionsReducer,
  customer: customerReducer,
  entities: entities,
});

export default rootReducer;
