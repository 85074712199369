import { connect } from 'react-redux';
import { identity, preventDefaultEvent } from '../../../util';
import getJobListingLocationName from '../../../selectors/getJobListingLocationName';
import getJobListingPlacementType from '../../../selectors/getJobListingPlacementType';
import getJobListingFunctionName from '../../../selectors/getJobListingFunctionName';
import SearchInput from '@thrivetrm/ui/components/SearchInput';
import CheckboxFiltersFormGroup from '../../forms/CheckboxFiltersFormGroup';
import Loader from '@thrivetrm/ui/components/Loader';
import CollapseContent from '../CollapseContent';

const JobListingsPageSidebar = ({
  functionNameFilters,
  isFetching,
  isLoaded,
  jobFunctionCounts,
  keywordFilter,
  locationFilters,
  locationNameCounts,
  onJobFunctionFiltersChange,
  onKeywordFilterChange,
  onLocationFiltersChange,
  onPlacementTypeFiltersChange,
  placementTypeCounts,
  placementTypeFilters,
}) => (
  <div className='JobListingsPageSidebar'>
    <form onSubmit={preventDefaultEvent}>
      <SearchInput
        className='u-marginBottom-12'
        disabled={!isLoaded}
        onChange={onKeywordFilterChange}
        onClear={() => onKeywordFilterChange('')}
        placeholder='Keyword Search'
        value={keywordFilter}
      />
      {isLoaded && (
        <CollapseContent expandable={true}>
          <h3>Locations</h3>
          <fieldset className='u-marginTop-4'>
            <legend className='sr-only'>Choose locations</legend>
            <CheckboxFiltersFormGroup
              itemCounts={locationNameCounts}
              onSelectedItemsChange={onLocationFiltersChange}
              selectedItems={locationFilters}
            />
          </fieldset>
          <h3>Categories / Functions</h3>
          <fieldset className='u-marginTop-4'>
            <legend className='sr-only'>Choose job categories</legend>
            <CheckboxFiltersFormGroup
              itemCounts={jobFunctionCounts}
              onSelectedItemsChange={onJobFunctionFiltersChange}
              selectedItems={functionNameFilters}
            />
          </fieldset>
          <h3>Placement Type</h3>
          <fieldset className='u-marginTop-4'>
            <legend className='sr-only'>Choose placement type</legend>
            <CheckboxFiltersFormGroup
              itemCounts={placementTypeCounts}
              onSelectedItemsChange={onPlacementTypeFiltersChange}
              selectedItems={placementTypeFilters}
            />
          </fieldset>
        </CollapseContent>
      )}
    </form>
    {isFetching && (
      <CollapseContent expandable={true}>
        <Loader />
      </CollapseContent>
    )}
  </div>
);

export default connect((state, { allJobListings }) => {
  if (allJobListings && allJobListings.has('ids')) {
    const ids = allJobListings.get('ids');
    return {
      jobFunctionCounts: ids
        .map((id) => getJobListingFunctionName(state, id))
        .countBy(identity)
        .toJS(),
      locationNameCounts: ids
        .map((id) => getJobListingLocationName(state, id))
        .countBy(identity)
        .toJS(),
      placementTypeCounts: ids
        .map((id) => getJobListingPlacementType(state, id))
        .countBy(identity)
        .toJS(),
    };
  }

  return {};
}, null)(JobListingsPageSidebar);
