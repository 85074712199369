import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import ApplyProfessionalInfoForm from './ApplyProfessionalInfoForm';
import ApplySelfIdentificationForm from './ApplySelfIdentificationForm';
import { useApiGet } from '@thrivetrm/ui/hooks/useApi';
import JobLocation from '../jobListings/JobLocation';
import JobPlacementType from '../jobListings/JobPlacementType';
import StepProgress from '@thrivetrm/ui/components/StepProgress';
import submitApplication from '../../actions/submitApplication';
import getApplicationSubmission from '../../selectors/getApplicationSubmission';
import ApplySuccess from './ApplySuccess';

import './ApplyModal.scss';

const STEPS = [
  {
    label: 'Professional Information',
    value: 'professional_information',
  },
  {
    label: 'Voluntary Self Identification',
    value: 'voluntary_self_identification',
  },
];

export const ApplyModal = ({ jobListingId, onHide, show }) => {
  const dispatch = useDispatch();
  const [isApplicationLoading, setIsApplicationLoading] = useState(false);
  const [
    loadJobListing,
    _isLoadingJobListing,
    _jobListingLoadError,
    jobListing,
  ] = useApiGet(`/api/v1/job_listings/${jobListingId}`);

  const jobTitle = jobListing?.job_listing?.job_title;

  const applicationSubmission = useSelector((state) =>
    getApplicationSubmission(state, jobListingId),
  );

  const allStepsCompleted = applicationSubmission?.get('applicationId');

  const hasVoluntaryIdentificationStatusEnabled =
    jobListing?.job_listing?.voluntary_identification_status;

  const [currentStepValue, setCurrentStepValue] = useState(
    'professional_information',
  );

  const initialFormState = {
    consentProvided: false,
    contactSourceId: null,
    disabilityStatusId: null,
    email: '',
    firstName: '',
    genderId: null,
    lastName: '',
    linkedInUrl: '',
    smsOptIn: false,
    phone: '',
    raceEthnicityIds: null,
    resume: null,
    coverLetter: null,
    sourceDetails: '',
    veteranStatusId: null,
    sexualOrientationId: null,
  };
  const [formState, setFormState] = useState(initialFormState);

  useEffect(() => {
    loadJobListing();
  }, [loadJobListing]);

  useEffect(() => {
    const handler = (event) => {
      event.returnValue = 'Warning! By leaving, your changes will be lost.';
    };
    if (hasVoluntaryIdentificationStatusEnabled) {
      window.addEventListener('beforeunload', handler);
    }
    return () => {
      window.removeEventListener('beforeunload', handler);
    };
  }, [hasVoluntaryIdentificationStatusEnabled]);

  const caslAttributes = useSelector((state) =>
    state.customer.get('caslAttributes'),
  );
  const isCaslActive = useSelector((state) =>
    state.customer.getIn(['flags', 'casl']),
  );
  const caslActive = isCaslActive && caslAttributes;

  const caslCompanyName = caslActive && caslAttributes.get('companyName');
  const caslPrivacyPolicyUrl =
    caslActive && caslAttributes.get('privacyPolicyUrl');

  const currentStepIndex = STEPS.findIndex(
    (item) => item.value === currentStepValue,
  );

  const handleFormPageSubmit = (dataFromStep) => {
    setFormState({
      ...formState,
      ...dataFromStep,
    });

    const isFirstStep = currentStepIndex === 0;

    if (hasVoluntaryIdentificationStatusEnabled && isFirstStep) {
      const nextStep = STEPS[currentStepIndex + 1].value;
      setCurrentStepValue(nextStep);
    } else {
      setIsApplicationLoading(true);
      dispatch(
        submitApplication({
          jobListingId: jobListingId,
          applicant: { ...formState, ...dataFromStep },
          setIsApplicationLoading: setIsApplicationLoading,
        }),
      );
    }
  };

  const handleOnBack = () => {
    const prevStep = STEPS[currentStepIndex - 1].value;
    setCurrentStepValue(prevStep);
  };

  const stepComponentMap = {
    professional_information: (
      <ApplyProfessionalInfoForm
        caslCompanyName={caslCompanyName}
        caslPrivacyPolicyUrl={caslPrivacyPolicyUrl}
        formData={formState}
        hasVoluntaryIdentificationStatusEnabled={
          hasVoluntaryIdentificationStatusEnabled
        }
        isApplicationLoading={isApplicationLoading}
        onClose={onHide}
        onSubmit={handleFormPageSubmit}
      />
    ),
    voluntary_self_identification: (
      <ApplySelfIdentificationForm
        caslCompanyName={caslCompanyName}
        caslPrivacyPolicyUrl={caslPrivacyPolicyUrl}
        formData={formState}
        isApplicationLoading={isApplicationLoading}
        onBack={handleOnBack}
        onSubmit={handleFormPageSubmit}
      />
    ),
  };

  const getStepLabel = () =>
    STEPS.find((step) => step.value === currentStepValue).label || '';

  return allStepsCompleted ? (
    <ApplySuccess
      applicationSubmission={applicationSubmission}
      currentStepValue={currentStepValue}
      hasVoluntaryIdentificationStatusEnabled={
        hasVoluntaryIdentificationStatusEnabled
      }
      jobListingId={jobListingId}
      jobTitle={jobTitle}
      onHide={onHide}
      show={show}
      steps={STEPS}
    />
  ) : (
    <Modal
      animation={false}
      backdrop='static'
      className='ApplyModal'
      dialogClassName='ApplyModal__dialog'
      onHide={onHide}
      show={show}
    >
      <Modal.Header
        bsPrefix='ApplyModal__Header'
        closeButton={true}
        closeLabel='close modal'
      >
        {hasVoluntaryIdentificationStatusEnabled ? (
          <>
            <StepProgress
              className='u-marginTop-16'
              currentStepValue={currentStepValue}
              steps={STEPS}
            />
            <Modal.Title className='ApplyModal__Title'>
              {getStepLabel()}
            </Modal.Title>
          </>
        ) : (
          <Modal.Title>{jobTitle}</Modal.Title>
        )}
        <JobLocation jobListingId={jobListingId} />
        <JobPlacementType jobListingId={jobListingId} />
      </Modal.Header>
      <Modal.Body className='ApplyModal__Body'>
        {stepComponentMap[currentStepValue]}
      </Modal.Body>
      <Modal.Footer className='ApplyModal__Footer'>
        {caslActive && (
          <div className='ApplyModal__CaslAddress'>
            <strong className='pull-right'>
              {[
                caslAttributes.get('companyName'),
                caslAttributes.get('addressOne'),
                caslAttributes.get('addressTwo'),
                caslAttributes.get('zipPostalCode'),
              ]
                .filter(Boolean)
                .join(' | ')}
              <br />
              {[caslAttributes.get('companyUrl'), caslAttributes.get('phone')]
                .filter(Boolean)
                .join(' | ')}
            </strong>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

ApplyModal.propTypes = {
  /**
   * The ID of the job listing to apply to
   */
  jobListingId: PropTypes.number.isRequired,

  /**
   * Called when the modal should be hidden (and `show` should be set to false)
   */
  onHide: PropTypes.func.isRequired,

  /**
   * Whether the modal should be shown.
   */
  show: PropTypes.bool.isRequired,
};

export default ApplyModal;
