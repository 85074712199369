/**
 * Gets the URL for a specific job listing page.
 * @param {String|Number} jobListingId The job listing ID
 * @param {String} [action] An optional action
 * @return {String} The URL for the job listing page.
 */
export default (jobListingId, action) => {
  const url = `/job/${jobListingId}`;

  // If there is an action, append that to the end of the URL, otherwise just return the URL as is.
  if (action) {
    return `${url}/${action}`;
  }

  return url;
};
