import { useLocation, useNavigate } from 'react-router-dom';
import JobListingsPage from './JobListingsPage';

// JobListingsPage uses a heaping plate of recompose spaghetti to manage the sidebar filters.
// Pieces of that recompose chain rely on React Router props being available in order to
// read from and push to the URL to manage the filter bahavior. The previous version of
// React Router (v5) automatically injected these props into the first child component of the
// route, so this worked fine. But React Router v6 no longer does this, because it expects
// you to use React Hooks to retrieve those values from any level of the component tree. This
// is a much better solution overall, but you can't use hooks inside of a recompose chain. So
// This wrapper component does that for us, and passes the expected router values into the
// actual JobListingsPage component as props, which the `mapQueryStringToFilters` recompose
// HOC then has access to.
const JobListingsPageRouteDataWrapper = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return <JobListingsPage location={location} navigate={navigate} />;
};

export default JobListingsPageRouteDataWrapper;
