/* eslint-disable no-restricted-imports */
// ^ Wrapping `react-select` in a core component is the desired use case
import { useState, useRef } from 'react';
import { components } from 'react-select';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Creatable from 'react-select/creatable';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';

import './MultiValueInput.scss';

// react-select uses Emotion for CSS-in-JS styles, and provides no way
// to disable it. You can override each style via functions that return
// style objects, so this is a hack to make every style function return
// an empty object. https://github.com/JedWatson/react-select/issues/2872
const styleProxy = new Proxy(
  {},
  {
    get: () => (_style) => {
      // Use the following console statement to inspect the native styles for debugging
      // console.log(_style.label, _style);
      // The menu can be forced open to inspect its styles via a prop of `menuIsOpen={true}`
      return null;
    },
  },
);

// By default, react-select removes the placeholder text when a value is present, and does
// not provide an easy way to override this behavior. This workaround allows us to add the
// placeholder back into place whenever the input has a value.
// https://github.com/JedWatson/react-select/issues/2152#issuecomment-597367759
const CustomValueContainer = (componentProps) => {
  const { placeholder } = componentProps.selectProps;
  const shouldDisplayPlaceholder = componentProps.hasValue;

  return (
    <components.ValueContainer {...componentProps}>
      {componentProps.children}
      {shouldDisplayPlaceholder && (
        <span className='MultiValueInput__persistentPlaceholder'>
          {placeholder}
        </span>
      )}
    </components.ValueContainer>
  );
};

const MultiValueInput = ({
  canUseCustomOptions,
  className,
  errorMessage,
  isDisabled,
  label,
  onChange,
  options,
  placeholder,
  shouldRenderAsInput,
  value,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('');
  const tokens = value || [];
  const containerRef = useRef(null);

  const handleChange = (newTokens) => {
    if (!isDisabled) {
      onChange(newTokens?.map((token) => token.value) ?? null);
    }
  };

  // react-select expects the `value` to be an array of objects with label/value
  // keys, but we expect an array of just the values. This function does the
  // translation for both provided options and custom (user-entered) options.
  const getCurrentTokens = () =>
    tokens.map((token) => {
      const tokenOption = options?.find((option) => option.value === token);
      return tokenOption || { label: token, value: token };
    });

  // Hack to make our "creatable" react-select input behave like a "non-creatable" input.
  // - When canUseCustomOptions is true, we leave the built-in validation behavior in place.
  // - When canUseCustomOptions is false, we set the validator function to always return false,
  //   which prevents any custom options from being added
  //
  // It would be great if we could pass `null` as a prop value to use built-in validation,
  // but react-select requires the value to be a function, so we're conditionally setting the
  // prop instead to achieve the same result :(
  const getConditionalProps = () => {
    const conditionalProps = {};
    if (!canUseCustomOptions) {
      conditionalProps.isValidNewOption = () => false;
    }
    return conditionalProps;
  };

  const containerClass = classnames('MultiValueInput', className, {
    'MultiValueInput--isInvalid': Boolean(errorMessage),
    'MultiValueInput--shouldRenderAsInput': shouldRenderAsInput,
  });

  const inputId = useRef(uniqueId('input'));

  const getCustomComponents = () => {
    const valueContainer = shouldRenderAsInput
      ? null
      : { ValueContainer: CustomValueContainer };

    return {
      DropdownIndicator: null,
      ...valueContainer,
    };
  };

  return (
    <div {...props} className={containerClass} ref={containerRef}>
      {label && (
        <label className='MultiValueInput__label' htmlFor={inputId.current}>
          {label}
        </label>
      )}

      <Creatable
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getConditionalProps()}
        backspaceRemovesValue={false}
        classNamePrefix='MultiValueInput'
        components={getCustomComponents()}
        createOptionPosition='first'
        inputId={inputId.current}
        inputValue={inputValue}
        isClearable={false}
        isDisabled={isDisabled}
        isMulti={true}
        noOptionsMessage={() => (options?.length ? 'No options' : null)}
        onChange={handleChange}
        onInputChange={setInputValue}
        options={options}
        placeholder={placeholder}
        styles={styleProxy}
        value={getCurrentTokens()}
      />

      {errorMessage && (
        <div className='TextInput__errorMessage' role='alert'>
          {errorMessage}
        </div>
      )}
    </div>
  );
};

MultiValueInput.defaultProps = {
  canUseCustomOptions: true,
  className: null,
  errorMessage: null,
  isDisabled: false,
  label: null,
  options: null,
  placeholder: null,
  shouldRenderAsInput: true,
  // Tracking empty value as `null` instead of an empty array allows 'required'
  // form validation to work when used through `<Form.MultiValueInput>`
  value: null,
};

MultiValueInput.propTypes = {
  /** True if the user should be able to add custom values that are not supplied as options. */
  canUseCustomOptions: PropTypes.bool,
  /** Class name to render on the root node. */
  className: PropTypes.string,
  /** Error content to render below the input (also triggers invalid UI state). */
  errorMessage: PropTypes.node,
  /** Whether the input is disabled. */
  isDisabled: PropTypes.bool,
  /** Label content to render above the input. */
  label: PropTypes.node,
  /** Callback function called with new value on change. */
  onChange: PropTypes.func.isRequired,
  /** Options to render in the dropdown menu */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  ),
  /** Placeholder text to render when no value exist. */
  placeholder: PropTypes.string,
  /**
   * False to persist the placeholder text after values have been entered,
   * and eliminate the border box.
   */
  shouldRenderAsInput: PropTypes.bool,
  /**
   * The value of the input. Although `values` is a more logical name for this prop,
   * we name it `value` to be consistent across all form components and for a more
   * seamless integration with Form component wrappers.
   */
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
};

export default MultiValueInput;
