import PropTypes from 'prop-types';

const formDataPropType = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ]),
);

export default formDataPropType;
