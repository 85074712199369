import { camelizeKeys } from 'humps';
import fetchAjax from './fetchAjax';
import {
  CUSTOMER_FETCH_START,
  CUSTOMER_FETCH_SUCCESS,
  CUSTOMER_FETCH_FAILURE,
} from './actionTypes';

const fetchCustomer = () => (dispatch) => {
  dispatch({
    type: CUSTOMER_FETCH_START,
  });

  fetchAjax({ url: `/api/v1/customer` })
    .then((data) => ({
      type: CUSTOMER_FETCH_SUCCESS,
      payload: {
        ...camelizeKeys(data),
      },
    }))
    .catch((error) => ({
      type: CUSTOMER_FETCH_FAILURE,
      payload: {
        error: error,
      },
    }))
    .then(dispatch);
};

export default fetchCustomer;
