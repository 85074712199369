import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose, lifecycle, withHandlers } from 'recompose';

import fetchJobListingsAction from '../../actions/fetchJobListings';
import getAllJobListings from '../../selectors/getAllJobListings';
import shouldFetchAllJobListingsSelector from '../../selectors/shouldFetchAllJobListings';

/**
 * A higher order component that:
 * 1. Provides the following props:
 *   * `allJobListings` (Map) the state of the allJobListings collection
 *   * `jobListingIds` (List) the list containing the IDs for all job listings.
 *   * `shouldFetchAllJobListings` (Boolean) whether, given the current application state,
 *      we should fetch job listings.
 *   * `fetchJobListings` (Function) a function that will fetch all job listings
 *   * `fetchAllJobListingsIfNeeded` (Function) a function that will fetch all job listings only
 *     if needed given the current applications tate.
 * 2. Fetches all job listings when the component is mounted, as needed.
 */
export default compose(
  connect(
    (state) => {
      const allJobListings = getAllJobListings(state);

      return {
        allJobListings: allJobListings,
        jobListingIds: allJobListings && allJobListings.get('ids'),
        shouldFetchAllJobListings: shouldFetchAllJobListingsSelector(state),
      };
    },
    (dispatch) => ({
      fetchJobListings: bindActionCreators(fetchJobListingsAction, dispatch),
    }),
  ),
  withHandlers({
    fetchAllJobListingsIfNeeded:
      ({ fetchJobListings, shouldFetchAllJobListings }) =>
      () => {
        if (shouldFetchAllJobListings) {
          fetchJobListings();
        }
      },
  }),
  lifecycle({
    componentDidMount: function () {
      this.props.fetchAllJobListingsIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchAllJobListingsIfNeeded();
    },
  }),
);
