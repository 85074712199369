import { pure } from 'recompose';
import PrimaryLink from '../PrimaryLink';
import PoweredByThriveTrm from './PoweredByThriveTrm.png';

import './PageFooter.scss';

const PageFooter = () => (
  <footer className='PageFooter'>
    <a href='https://thrivetrm.com' rel='noopener noreferrer' target='_blank'>
      <img alt='Powered by ThriveTRM' height={35} src={PoweredByThriveTrm} />
    </a>
    <ul className='PageFooter__links'>
      <li>
        <PrimaryLink to='/terms'>Terms of Use</PrimaryLink>
      </li>
      <li>
        <PrimaryLink to='/privacy'>Privacy Policy</PrimaryLink>
      </li>
    </ul>
  </footer>
);

export default pure(PageFooter);
