import { Map } from 'immutable';
import { APP_CONNECTIVITY_CHANGED } from '../actions/actionTypes';

const appReducer = (state = new Map({ isConnected: true }), action) => {
  switch (action.type) {
    case APP_CONNECTIVITY_CHANGED: {
      return state.set('isConnected', action.payload);
    }
    default: {
      return state;
    }
  }
};

export default appReducer;
