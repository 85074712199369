import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { compose, mapProps, setDisplayName } from 'recompose';

import './PrimaryLink.scss';

/**
 * Renders a "primary"-styled link
 */
export default compose(
  setDisplayName('PrimaryLink'),
  mapProps(({ className, ...props }) => ({
    className: classnames(className, 'PrimaryLink'),
    ...props,
  })),
)(Link);
