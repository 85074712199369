/**
 * Composes a series of reducer functions, where each reducer is called, in sequence, and
 * given the state returned from the previous reducer.
 * @param {...Function} reducers The reducer functions to compose.
 * @return {Function} A single function that calls each reducer function in sequence.
 */
export default function composeReducers(...reducers) {
  return (state, action) =>
    reducers.reduce(
      (accumulatedState, reducer) => reducer(accumulatedState, action),
      state,
    );
}
