import PropTypes from 'prop-types';
import TimeAgo from '../TimeAgo';

/**
 * Renders a job listing record's relative posted date
 */
const JobPostedDate = ({ date }) => (
  <span className='JobPostedDate'>
    Posted <TimeAgo value={date} />
  </span>
);

JobPostedDate.propTypes = {
  /**
   * The ISO8601-formatted created at date for the job posting.
   */
  date: PropTypes.string.isRequired,
};

export default JobPostedDate;
