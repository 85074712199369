import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import JobLocation from '../../jobListings/JobLocation';
import JobPlacementType from '../../jobListings/JobPlacementType';
import JobFunctionName from '../../jobListings/JobFunctionName';
import JobPostedDate from '../../jobListings/JobPostedDate';
import JobTitle from '../../jobListings/JobTitle';
import JobDescriptionSummary from '../../jobListings/JobDescriptionSummary';
import getJobListingFunctionName from 'selectors/getJobListingFunctionName';
import getJobListingPublishedDate from 'selectors/getJobListingPublishedDate';
import Icon from '@thrivetrm/ui/components/Icon';

import './JobListingSummary.scss';

const JobListingSummary = ({ jobListingId }) => {
  const date = useSelector((state) =>
    getJobListingPublishedDate(state, jobListingId),
  );

  const jobFunctionName = useSelector((state) =>
    getJobListingFunctionName(state, jobListingId),
  );

  return (
    <div className='JobListingSummary'>
      <div className='JobListingSummary__body'>
        <div className='JobListingSummary__header row'>
          <div className='JobListingSummary__title col-xs-7 col-sm-5'>
            <h2 className='JobListingSummary__job-title'>
              <Link to={`/job/${jobListingId}`}>
                <JobTitle jobListingId={jobListingId} />
              </Link>
            </h2>
            <h3 className='JobListingSummary__job-function'>
              <JobFunctionName jobListingId={jobListingId} />
            </h3>
          </div>
          <div className='JobListingSummary__location-placement col-xs-5 col-sm-4'>
            <JobLocation jobListingId={jobListingId} />
            <JobPlacementType jobListingId={jobListingId} />
          </div>
          <div className='JobListingSummary__time col-xs-12 col-sm-3'>
            <JobPostedDate date={date} />
          </div>
        </div>
        <JobDescriptionSummary jobListingId={jobListingId} />
      </div>
      <div className='JobListingSummary__select'>
        <Link
          className='JobListingSummary__select-link'
          to={`/job/${jobListingId}`}
        >
          <span className='sr-only'>
            Visit {jobFunctionName || `Job listing ${jobListingId}`} page{' '}
          </span>
          <Icon aria-hidden='true' size='large' type='chevronRight' />
        </Link>
      </div>
    </div>
  );
};

JobListingSummary.propTypes = {
  jobListingId: PropTypes.number.isRequired,
};

export default JobListingSummary;
