import PropTypes from 'prop-types';

// Validation props for our <Form> component inputs are passed straight
// through to react-hook-form. https://react-hook-form.com/api#registerRef
export default PropTypes.shape({
  max: PropTypes.shape({
    message: PropTypes.node,
    value: PropTypes.number,
  }),
  maxLength: PropTypes.shape({
    message: PropTypes.node,
    value: PropTypes.number,
  }),
  min: PropTypes.shape({
    message: PropTypes.node,
    value: PropTypes.number,
  }),
  minLength: PropTypes.shape({
    message: PropTypes.node,
    value: PropTypes.number,
  }),
  pattern: PropTypes.shape({
    message: PropTypes.node,
    value: PropTypes.instanceOf(RegExp),
  }),
  required: PropTypes.shape({
    message: PropTypes.node,
    value: PropTypes.bool,
  }),
  validate: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.objectOf(PropTypes.func),
  ]),
});
