import { createSelector } from 'reselect';
import getJobListingTitle from './getJobListingTitle';
import getJobListingFunctionName from './getJobListingFunctionName';
import getJobListingLocationName from './getJobListingLocationName';

/**
 * Gets a string that can be used to find all terms related to a job listing. This is used for
 * filtering a job listing on keywords.
 * @param {Object} state the application state
 * @param {String|Number} jobListingId the ID for the job listing.
 * @return {String} a string containing all the keywords that should be considered when filtering
 *   a job listing.
 */
export default createSelector(
  getJobListingTitle,
  getJobListingFunctionName,
  getJobListingLocationName,
  (title, functionName, locationName) =>
    [title, functionName, locationName].join(' '),
);
