import PropTypes from 'prop-types';
import classnames from 'classnames';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import Icon from '@thrivetrm/ui/components/Icon';

import './PageError.scss';

/**
 * Renders an error message when a page's content failed to load.
 */
const PageError = ({
  defaultMessage,
  error,
  message,
  onRetry,
  secondaryMessage,
  title,
}) => (
  <div
    className={classnames('PageError', {
      [`PageError--${error && error.status}`]: error && error.status,
    })}
  >
    <h3>
      <Icon backgroundColor='red' color='white' size='small' type='alert' />{' '}
      {title}
    </h3>
    <p>{message || error.message || defaultMessage}</p>
    {secondaryMessage && <p>{secondaryMessage}</p>}
    {onRetry && (
      <ButtonSecondary
        icon='refresh'
        label='Retry'
        onClick={onRetry}
        size='small'
      />
    )}
  </div>
);

PageError.propTypes = {
  /**
   * The default error message to display when `message` and `error.message` are not provided.
   */
  defaultMessage: PropTypes.string,

  error: PropTypes.shape({
    message: PropTypes.string,
    // The status code (403, 500, etc...)
    status: PropTypes.number,
  }),

  /**
   * The error message to display. This takes priority over `error.message` and `defaultMessage`
   */
  message: PropTypes.string,

  /**
   * Called when the try button is clicked. When not provided, the retry button will not be rendered.
   */
  onRetry: PropTypes.func,

  /**
   * A secondary message to display. This may be a string or the value `false` to not display it.
   */
  secondaryMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([false]),
  ]),

  /**
   * The title.
   */
  title: PropTypes.string,
};

PageError.defaultProps = {
  defaultMessage: 'An error occured',
  secondaryMessage: 'There may be a server or network problem.',
  title: 'Error',
};

export default PageError;
