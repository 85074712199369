import url from 'url';
import JobShareLink from './JobShareLink';

export const createLinkedInShareLink = (jobListing, jobUrl) =>
  url.format({
    protocol: 'https',
    host: 'www.linkedin.com',
    pathname: '/shareArticle',
    query: {
      mini: 'true',
      title: jobListing.get('jobTitle'),
      summary: `${jobListing.get('jobTitle')} opportunity in ${jobListing.get(
        'location',
      )}`,
      url: jobUrl,
    },
  });

const JobShareLinkedInLink = ({ jobListing, jobUrl }) => (
  <JobShareLink
    href={jobListing && createLinkedInShareLink(jobListing, jobUrl)}
    icon='linkedIn'
  />
);

export default JobShareLinkedInLink;
