import selectn from 'selectn';
import { Map } from 'immutable';
import {
  JOB_LISTING_FETCH_START,
  JOB_LISTING_FETCH_SUCCESS,
  JOB_LISTING_FETCH_FAILURE,
} from '../../actions/actionTypes';

const selectJobListingId = selectn('payload.jobListingId');
const selectError = selectn('payload.error');

const jobListingsReducer = (state = new Map(), action) => {
  switch (action.type) {
    case JOB_LISTING_FETCH_START: {
      return state.setIn([selectJobListingId(action), 'isFetching'], true);
    }
    case JOB_LISTING_FETCH_SUCCESS: {
      const id = selectJobListingId(action);
      return state.setIn([id, 'isFetching'], false).deleteIn([id, 'error']);
    }
    case JOB_LISTING_FETCH_FAILURE: {
      const id = selectJobListingId(action);
      return state
        .setIn([id, 'isFetching'], false)
        .setIn([id, 'error'], selectError(action));
    }
    default: {
      return state;
    }
  }
};

export default jobListingsReducer;
