import Modal from '@thrivetrm/ui/components/Modal';
import JobLocation from '../jobListings/JobLocation';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import JobPlacementType from '../jobListings/JobPlacementType';
import StepProgress from '@thrivetrm/ui/components/StepProgress';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import './ApplySuccess.scss';

const ApplySuccess = ({
  applicationSubmission,
  currentStepValue,
  hasVoluntaryIdentificationStatusEnabled,
  jobListingId,
  jobTitle,
  onHide,
  show,
  steps,
}) => (
  <Modal
    className='ApplyModal'
    isOpen={show}
    onClose={onHide}
    size='small'
    subTitle={
      hasVoluntaryIdentificationStatusEnabled ? null : (
        <>
          <JobLocation jobListingId={jobListingId} />
          <JobPlacementType jobListingId={jobListingId} />
        </>
      )
    }
    title={hasVoluntaryIdentificationStatusEnabled ? 'Complete!' : jobTitle}
  >
    <Modal.Body className='ApplyModal__Body'>
      {hasVoluntaryIdentificationStatusEnabled ? (
        <StepProgress
          className='u-marginTop-16'
          currentStepValue={currentStepValue}
          steps={steps}
        />
      ) : null}
      <div
        className={classNames('ApplySuccess', {
          'u-textAlign-c': hasVoluntaryIdentificationStatusEnabled,
        })}
      >
        <p className='u-fontSize-large'>
          Thank you for your
          {jobTitle && (
            <span className='ApplySuccess__jobTitle'> {jobTitle} </span>
          )}
          application!
        </p>
        <p className='u-marginTop-32'>
          You will receive a confirmation email at
          {applicationSubmission &&
          applicationSubmission.getIn(['fields', 'email']) ? (
            <span className='ApplySuccess__email'>
              {' '}
              {applicationSubmission.getIn(['fields', 'email'])}
            </span>
          ) : (
            ' the email address provided'
          )}
          .
        </p>
      </div>
    </Modal.Body>
    {hasVoluntaryIdentificationStatusEnabled ? (
      <Modal.Footer className='ApplyModal__Footer'>
        <ButtonPrimary
          data-testid='close-success-modal'
          label='Close'
          onClick={onHide}
        />
      </Modal.Footer>
    ) : null}
  </Modal>
);

ApplySuccess.defaultProps = {
  hasVoluntaryIdentificationStatusEnabled: false,
};

ApplySuccess.propTypes = {
  applicationSubmission: ImmutablePropTypes.mapContains({
    fields: ImmutablePropTypes.mapContains({
      email: PropTypes.string,
    }),
  }),
  currentStepValue: PropTypes.string.isRequired,
  hasVoluntaryIdentificationStatusEnabled: PropTypes.bool,
  jobListingId: PropTypes.number.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    }),
  ).isRequired,
};

export default ApplySuccess;
