import classnames from 'classnames';
import PropTypes from 'prop-types';
import facebookIcon from '../../../images/facebook-f.svg';
import twitterIcon from '../../../images/twitter.svg';
import linkedInIcon from '../../../images/linkedin-in.svg';
import mailToIcon from '../../../images/envelope.svg';

import './JobShareLink.scss';

const IconTypes = {
  facebook: facebookIcon,
  twitter: twitterIcon,
  linkedIn: linkedInIcon,
  mailTo: mailToIcon,
};

const JobShareLink = ({ className, icon, ...rest }) => (
  <a
    aria-label={`Visit ${icon} page`}
    className={classnames('JobShareLink', className)}
    rel='noopener noreferrer'
    target='_blank'
    {...rest}
  >
    <img
      alt='Social Link Icon'
      aria-hidden='true'
      height={16}
      src={IconTypes[icon]}
    />
  </a>
);

JobShareLink.propTypes = {
  icon: PropTypes.oneOf(Object.keys(IconTypes)),
};

export default JobShareLink;
