import CollapseContent from '../CollapseContent';
import JobPageFooter from './JobPageFooter';
import JobDescription from '../../jobListings/JobDescription';
import JobShareLinks from '../../jobListings/sharing/JobShareLinks';

import JobTitle from '../../jobListings/JobTitle';
import JobFunctionName from '../../jobListings/JobFunctionName';
import './JobPageContent.scss';

/**
 * Renders the page for displaying a single job listing
 */
const JobPageContent = ({ jobListing }) => (
  <div className='JobPageContent'>
    <div className='ContentSection JobPageContent__ContentSection'>
      <JobShareLinks horizontal={true} jobListing={jobListing} />
      <h1 className='JobPageSidebar__title'>
        <JobTitle jobListingId={jobListing.get('id')} />
      </h1>
      <h2 className='JobPageSidebar__function'>
        <JobFunctionName jobListingId={jobListing.get('id')} />
      </h2>

      <h3>Job Description</h3>
      <JobDescription description={jobListing.get('description')} />
    </div>
    <CollapseContent inverted={true}>
      <JobPageFooter jobListing={jobListing} />
    </CollapseContent>
  </div>
);

export default JobPageContent;
