import { createSelector } from 'reselect';
import getAllJobListings from './getAllJobListings';

/**
 * Gets a value indicating whether -- given the current application state -- the collection of all
 * job listings should be fetched from the server.
 * @param {Object} state the application state
 * @return {Boolean} True if all job listings need to be fetched from the server; otherwise, false.
 */
export default createSelector(getAllJobListings, (allJobListings) => {
  if (!allJobListings) {
    return true;
  }

  if (allJobListings.get('isFetching')) {
    return false;
  }

  if (allJobListings.has('error')) {
    return false;
  }

  return (
    !allJobListings.has('ids') || Boolean(allJobListings.get('isInvalidated'))
  );
});
