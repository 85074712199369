import selectn from 'selectn';
import { List, Map } from 'immutable';
import {
  JOB_LISTINGS_FETCH_START,
  JOB_LISTINGS_FETCH_SUCCESS,
  JOB_LISTINGS_FETCH_FAILURE,
} from '../actions/actionTypes';
import { jobListing as jobListingSchema } from '../schemas';

const allJobListingsReducer = (state = new Map(), action) => {
  switch (action.type) {
    case JOB_LISTINGS_FETCH_START: {
      return state.set('isFetching', true);
    }
    case JOB_LISTINGS_FETCH_SUCCESS: {
      return state
        .set('isFetching', false)
        .set(
          'ids',
          new List(selectn(`payload.result.${jobListingSchema.key}`, action)),
        )
        .delete('error');
    }
    case JOB_LISTINGS_FETCH_FAILURE: {
      return state
        .set('isFetching', false)
        .set('error', selectn('payload.error', action));
    }
    default: {
      return state;
    }
  }
};

export default allJobListingsReducer;
