import PropTypes from 'prop-types';
import classnames from 'classnames';

import './FilterLabel.scss';

/**
 * Renders the label for a filter item which shows a label followed by a count
 */
export const FilterLabel = ({ children, className, count }) => {
  const containerClass = classnames('FilterLabel', className);

  return (
    <span className={containerClass}>
      <span className='FilterLabel--content'>{children}</span>
      <span className='FilterLabel--count'>{count}</span>
    </span>
  );
};

FilterLabel.propTypes = {
  /**
   * The components or string to render as the label.
   */
  children: PropTypes.node.isRequired,

  /**
   * The class name to apply to the container
   */
  className: PropTypes.string,

  /**
   * The count to render after the label
   */
  count: PropTypes.number,
};

FilterLabel.defaultProps = {
  className: null,
  count: 0,
};

export default FilterLabel;
