import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import getJobListingUrl from '../../../selectors/getJobListingUrl';
import JobShareFacebookLink from './JobShareFacebookLink';
import JobShareLinkedInLink from './JobShareLinkedInLink';
import JobShareMailtoLink from './JobShareMailtoLink';
import JobShareTwitterLink from './JobShareTwitterLink';
import { toAbsoluteUrl } from '../../../util';

import './JobShareLinks.scss';

const JobShareLinks = ({ horizontal, jobListing }) => {
  const { action } = useParams();
  const jobUrl = toAbsoluteUrl(getJobListingUrl(jobListing.get('id'), action));

  return (
    <ul
      aria-label='social media links'
      className={classnames('JobShareLinks', {
        'JobShareLinks--horizontal': horizontal,
      })}
    >
      <li>
        <JobShareLinkedInLink jobListing={jobListing} jobUrl={jobUrl} />
      </li>
      <li>
        <JobShareFacebookLink jobListing={jobListing} jobUrl={jobUrl} />
      </li>
      <li>
        <JobShareTwitterLink jobListing={jobListing} jobUrl={jobUrl} />
      </li>
      <li>
        <JobShareMailtoLink jobListing={jobListing} jobUrl={jobUrl} />
      </li>
    </ul>
  );
};

export default JobShareLinks;
