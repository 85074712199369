import JobPostedDate from '../../jobListings/JobPostedDate';
import RelatedJobsLink from '../../jobListings/RelatedJobsLink';

/**
 * Renders the page for displaying a single job listing
 */
const JobPageFooter = ({ jobListing }) => (
  <div className='JobPageFooter row'>
    <div className='col-xs-6'>
      <RelatedJobsLink jobListing={jobListing} />
    </div>
    <div className='col-xs-6 text-right'>
      <JobPostedDate date={jobListing.get('publishedDate')} />
    </div>
  </div>
);

export default JobPageFooter;
