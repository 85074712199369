export const APP_CONNECTIVITY_CHANGED = 'APP_CONNECTIVITY_CHANGED';

export const CONTACT_SOURCES_FETCH_START = 'CONTACT_SOURCES_FETCH_START';
export const CONTACT_SOURCES_FETCH_SUCCESS = 'CONTACT_SOURCES_FETCH_SUCCESS';
export const CONTACT_SOURCES_FETCH_FAILURE = 'CONTACT_SOURCES_FETCH_FAILURE';

export const CUSTOMER_FETCH_START = 'CUSTOMER_FETCH_START';
export const CUSTOMER_FETCH_SUCCESS = 'CUSTOMER_FETCH_SUCCESS';
export const CUSTOMER_FETCH_FAILURE = 'CUSTOMER_FETCH_FAILURE';

export const JOB_LISTINGS_FETCH_START = 'JOB_LISTINGS_FETCH_START';
export const JOB_LISTINGS_FETCH_SUCCESS = 'JOB_LISTINGS_FETCH_SUCCESS';
export const JOB_LISTINGS_FETCH_FAILURE = 'JOB_LISTINGS_FETCH_FAILURE';

export const JOB_LISTING_FETCH_START = 'JOB_LISTING_FETCH_START';
export const JOB_LISTING_FETCH_SUCCESS = 'JOB_LISTING_FETCH_SUCCESS';
export const JOB_LISTING_FETCH_FAILURE = 'JOB_LISTING_FETCH_FAILURE';

export const APPLICANT_SUBMIT_START = 'APPLICANT_SUBMIT_START';
export const APPLICANT_SUBMIT_SUCCESS = 'APPLICANT_SUBMIT_SUCCESS';
export const APPLICANT_SUBMIT_FAILURE = 'APPLICANT_SUBMIT_FAILURE';
export const APPLICANT_STORE = 'APPLICANT_STORE';
