import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { htmlToText } from 'html-to-text';
import Truncate from 'react-truncate';
import getJobListingDescription from '../../selectors/getJobListingDescription';

/**
 * Renders a summary of a job listing record's description.
 */
export const JobDescriptionSummary = ({ description, lines }) => (
  <div className='JobDescriptionSummary'>
    <Truncate lines={lines}>{htmlToText(description)}</Truncate>
  </div>
);

JobDescriptionSummary.propTypes = {
  /**
   * The (full) job description
   */
  description: PropTypes.string,

  /**
   * The maximum number of summary lines to show.
   */
  lines: PropTypes.number,
};

JobDescriptionSummary.defaultProps = {
  lines: 4,
};

/**
 * Connected JobDescriptionSummary, looks up the location name provided a `jobListingId` value.
 */
export default connect(
  (state, { jobListingId }) => ({
    description: getJobListingDescription(state, jobListingId),
  }),
  null,
)(JobDescriptionSummary);
