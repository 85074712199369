import selectn from 'selectn';
import { Map } from 'immutable';
import {
  CUSTOMER_FETCH_START,
  CUSTOMER_FETCH_SUCCESS,
  CUSTOMER_FETCH_FAILURE,
} from '../actions/actionTypes';

const customerReducer = (state = new Map(), action) => {
  switch (action.type) {
    case CUSTOMER_FETCH_START: {
      return state.set('isFetching', true);
    }
    case CUSTOMER_FETCH_SUCCESS: {
      return state
        .set('isFetching', false)
        .set('lastFetched', Date.now())
        .merge(selectn('payload.customer', action))
        .delete('error');
    }
    case CUSTOMER_FETCH_FAILURE: {
      return state
        .set('isFetching', false)
        .set('lastFetched', Date.now())
        .set('error', selectn('payload.error', action));
    }
    default: {
      return state;
    }
  }
};

export default customerReducer;
