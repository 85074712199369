import { connect } from 'react-redux';

import getJobListingFunctionName from '../../selectors/getJobListingFunctionName';

/**
 * A higher order component that filters a list of jobListingIds based on an array of
 * filter values (provided as a prop named `functionNameFilters`)
 */
export default connect((state, { functionNameFilters, jobListingIds }) => {
  if (jobListingIds && functionNameFilters.length) {
    return {
      jobListingIds: jobListingIds.filter((id) =>
        functionNameFilters.includes(getJobListingFunctionName(state, id)),
      ),
    };
  }

  return {};
}, null);
