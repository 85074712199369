import url from 'url';
import JobShareLink from './JobShareLink';

export const createTwitterShareLink = (jobListing, jobUrl) =>
  url.format({
    protocol: 'https',
    host: 'twitter.com',
    pathname: '/intent/tweet',
    query: {
      text: `${jobListing.get('jobTitle')} opportunity in ${jobListing.get(
        'location',
      )}:`,
      url: jobUrl,
    },
  });

const JobShareTwitterLink = ({ jobListing, jobUrl }) => (
  <JobShareLink
    href={jobListing && createTwitterShareLink(jobListing, jobUrl)}
    icon='twitter'
  />
);

export default JobShareTwitterLink;
