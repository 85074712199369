import { createSelector } from 'reselect';
import getJobListing from './getJobListing';

/**
 * Gets the publishedDate value for a specific job listing.
 * @param {Object} state the application state
 * @param {String|Number} jobListingId the ID for the job listing.
 * @return {String} the date published for the job listing, if found.
 */
export default createSelector(
  getJobListing,
  (jobListing) => jobListing && jobListing.get('publishedDate'),
);
