import PropTypes from 'prop-types';
import { compose } from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Helmet } from 'react-helmet';
import PageError from '../PageError';
import Loader from '@thrivetrm/ui/components/Loader';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import PageContainer from '../PageContainer';
import JobListingSummary from './JobListingSummary';
import JobListingsPageSidebar from './JobListingsPageSidebar';
import mapQueryStringToFilters from './mapQueryStringToFilters';

import withAllJobListings from '../../jobListings/withAllJobListings';
import filterByFunctionNames from '../../jobListings/filterByFunctionNames';
import filterByKeyword from '../../jobListings/filterByKeyword';
import filterByLocationNames from '../../jobListings/filterByLocationNames';
import filterByPlacementTypes from '../../jobListings/filterByPlacementTypes';

import './JobListingsPage.scss';

const JobListingsPage = ({
  allJobListings,
  clearFilters,
  fetchJobListings,
  functionNameFilters,
  jobListingIds,
  keywordFilter,
  locationFilters,
  placementTypeFilters,
  setFunctionNameFilters,
  setKeywordFilter,
  setLocationFilters,
  setPlacementTypeFilters,
}) => {
  const isFetching = allJobListings && allJobListings.get('isFetching');
  const hasError = allJobListings && allJobListings.has('error');
  const isLoaded = allJobListings && allJobListings.has('ids');
  const hasAnyListings = isLoaded && allJobListings.get('ids').size > 0;
  const hasFilteredListings =
    isLoaded && jobListingIds && jobListingIds.size > 0;
  const filterMiss = hasAnyListings && !hasFilteredListings;

  return (
    <PageContainer
      className='JobListingsPage'
      sidebar={
        <JobListingsPageSidebar
          allJobListings={allJobListings}
          functionNameFilters={functionNameFilters}
          isFetching={isFetching}
          isLoaded={isLoaded}
          keywordFilter={keywordFilter}
          locationFilters={locationFilters}
          onJobFunctionFiltersChange={setFunctionNameFilters}
          onKeywordFilterChange={setKeywordFilter}
          onLocationFiltersChange={setLocationFilters}
          onPlacementTypeFiltersChange={setPlacementTypeFilters}
          placementTypeFilters={placementTypeFilters}
        />
      }
      sidebarExpandable={true}
      sidebarTitle='Filters'
    >
      <Helmet>
        <title>Open Jobs</title>
      </Helmet>
      <h1 className='JobListingsPage__mainTitle'>Open Jobs</h1>
      {isLoaded && (
        <ul className='JobListingsPage__list'>
          {jobListingIds &&
            jobListingIds
              .map((id) => (
                <li className='JobListingsPage__list-item' key={id}>
                  <div className='ContentSection'>
                    <JobListingSummary jobListingId={id} />
                  </div>
                </li>
              ))
              .toArray()}
        </ul>
      )}
      {isFetching && <Loader />}
      {filterMiss && (
        <div className='JobListingsPage__empty--filtered'>
          <p>No jobs match the filters selected.</p>
          <ButtonSecondary
            label='Reset filters'
            onClick={clearFilters}
            size='small'
          />
        </div>
      )}
      {isLoaded && !hasAnyListings && (
        <p className='JobListingsPage__empty--all'>
          No jobs are currently available. Please check back another time!
        </p>
      )}
      {hasError && !isFetching && (
        <PageError
          error={allJobListings.get('error')}
          message='There was an error loading available jobs.'
          onRetry={fetchJobListings}
          title='Unable to load job listings'
        />
      )}
    </PageContainer>
  );
};

JobListingsPage.propTypes = {
  allJobListings: ImmutablePropTypes.mapContains({
    isFetching: PropTypes.bool,
  }),
  clearFilters: PropTypes.func.isRequired,
  functionNameFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  jobListingIds: ImmutablePropTypes.listOf(PropTypes.number),
  keywordFilter: PropTypes.string.isRequired,
  locationFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  placementTypeFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  setFunctionNameFilters: PropTypes.func.isRequired,
  setKeywordFilter: PropTypes.func.isRequired,
  setLocationFilters: PropTypes.func.isRequired,
  setPlacementTypeFilters: PropTypes.func.isRequired,
};

export default compose(
  mapQueryStringToFilters,
  withAllJobListings,
  filterByKeyword,
  filterByLocationNames,
  filterByFunctionNames,
  filterByPlacementTypes,
)(JobListingsPage);
