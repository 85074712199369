import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import Loader from '@thrivetrm/ui/components/Loader';
import JobPageContent from './JobPageContent';
import { JobPageSidebar } from './JobPageSidebar';
import PageContainer from '../PageContainer';
import PageError from '../PageError';
import fetchJobListingAction from 'actions/fetchJobListing';
import getJobListing from 'selectors/getJobListing';
import shouldFetchJobListingSelector from 'selectors/shouldFetchJobListing';

const NOT_FOUND_STATUS_CODE = 404;

/**
 * Renders the page for displaying a single job listing
 */
export const JobPage = () => {
  const dispatch = useDispatch();
  const { jobListingId } = useParams();

  const jobListing = useSelector((state) => getJobListing(state, jobListingId));
  const shouldFetchJobListing = useSelector((state) =>
    shouldFetchJobListingSelector(state, jobListingId),
  );

  useEffect(() => {
    if (shouldFetchJobListing) {
      dispatch(fetchJobListingAction({ jobListingId: jobListingId }));
    }
  }, [dispatch, jobListingId, shouldFetchJobListing]);

  const isFetching = jobListing && jobListing.get('isFetching');
  const hasError = jobListing && jobListing.has('error');
  const isNotFound =
    hasError && jobListing.get('error').status === NOT_FOUND_STATUS_CODE;

  useEffect(() => {
    // Force the page to scroll to the top on initial render, which prevents the scroll position
    // from carrying over between the job listings view and the job detail view.
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContainer
      className='JobPage'
      sidebar={<JobPageSidebar jobListing={jobListing} />}
    >
      <Helmet>
        <title>{jobListing && jobListing.get('jobTitle')}</title>
      </Helmet>
      {jobListing && jobListing.has('id') && (
        <JobPageContent jobListing={jobListing} />
      )}
      {isFetching && <Loader />}

      {hasError && isNotFound && (
        <div className='JobPage__error JobPage_error--404'>
          <h2>Job not found</h2>
          <p>
            The job you requested could not be found. You may have an outdated
            or incorrect link.
          </p>
          <Link to='/'>View all job listings</Link>
        </div>
      )}
      {hasError && !isNotFound && !isFetching && (
        <PageError
          error={jobListing.get('error')}
          message='There was an error loading the details for the job requested.'
          onRetry={() => {
            dispatch(fetchJobListingAction({ jobListingId: jobListingId }));
          }}
          title='Unable to load job information'
        />
      )}
    </PageContainer>
  );
};

export default JobPage;
