import { connect } from 'react-redux';
import { createKeywordsRegex } from '../../util';
import getJobListingFilterableText from '../../selectors/getJobListingFilterableText';

/**
 * A higher order component that filters a list of jobListingIds based on a filter string
 * (provided as a `keywordFilter` prop)
 */
export default connect((state, { jobListingIds, keywordFilter }) => {
  if (jobListingIds && keywordFilter && keywordFilter.trim()) {
    const keywordRegex = createKeywordsRegex(keywordFilter);
    return {
      jobListingIds: jobListingIds.filter((id) =>
        keywordRegex.test(getJobListingFilterableText(state, id)),
      ),
    };
  }

  return {};
}, null);
