import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './components/app/App';
import configureStore from './store/configureStore';
import fetchCustomer from './actions/fetchCustomer';
import setAppConnectivity from './actions/setAppConnectivity';

import './index.scss';

const rootEl = document.getElementById('root');

const store = configureStore();
store.dispatch(fetchCustomer());
store.dispatch(
  setAppConnectivity(
    typeof navigator.onLine === 'boolean' ? navigator.onLine : true,
  ),
);

window.addEventListener('offline', () => {
  store.dispatch(setAppConnectivity(false));
});

window.addEventListener('online', () => {
  store.dispatch(setAppConnectivity(true));
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootEl,
);

if (module.hot) {
  module.hot.accept('./components/app/App', () => {
    import('./components/app/App').then(({ default: NextApp }) => {
      ReactDOM.render(
        <Provider store={store}>
          <NextApp />
        </Provider>,
        rootEl,
      );
    });
  });
}
