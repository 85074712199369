import { normalize } from 'normalizr';
import { camelizeKeys } from 'humps';
import { jobListing as jobListingSchema } from '../schemas';

import fetchAjax from './fetchAjax';
import {
  JOB_LISTINGS_FETCH_START,
  JOB_LISTINGS_FETCH_SUCCESS,
  JOB_LISTINGS_FETCH_FAILURE,
} from './actionTypes';

const fetchJobListings = () => (dispatch) => {
  dispatch({
    type: JOB_LISTINGS_FETCH_START,
  });

  fetchAjax({ url: '/api/v1/job_listings' })
    .then((data) => ({
      type: JOB_LISTINGS_FETCH_SUCCESS,
      payload: normalize(camelizeKeys(data), {
        jobListings: [jobListingSchema],
      }),
    }))
    .catch((error) => ({
      type: JOB_LISTINGS_FETCH_FAILURE,
      payload: { error: error },
    }))
    .then(dispatch);
};

export default fetchJobListings;
