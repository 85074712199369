import PropTypes from 'prop-types';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import classnames from 'classnames';
import { compose, withHandlers, withState } from 'recompose';
import PageFooter from './PageFooter';

import './PageContainer.scss';

/**
 * The `PageContainer` wraps the main content of an entire page, and may optionally
 * show  content in a sidebar. The sidebar will be displayed at the left of the
 * page in larger viewports, but in smaller-width viewports will be
 * displayed at the top of the page below the application header ("reduced" mode).
 *
 * When in reduced mode:
 *  * Any content wrapped in a `CollapseContent` component will be hidden.
 *  * Setting `sidebarExpandable` instructs the sidebar to display a hamburger
 *    menu bytton that well alow expanding of `CollapseContent` whose `expandable`
 *    property is set to true.
 */
const PageContainer = ({
  children,
  className,
  fixedWidth,
  isSidebarExpanded,
  onToggleSidebar,
  sidebar,
  sidebarExpandable,
  sidebarTitle,
}) => (
  <>
    <main
      className={classnames(className, 'container-fluid', 'PageContainer', {
        'container-fluid': !fixedWidth,
        container: fixedWidth,
        'PageContainer--with-sidebar': Boolean(sidebar),
        'PageContainer--with-sidebar-expandable': Boolean(sidebarExpandable),
        'PageContainer--with-sidebar-expanded':
          sidebarExpandable && isSidebarExpanded,
      })}
    >
      {sidebar ? (
        <div className='row'>
          <section className='col-sm-4 col-md-3 PageContainer__sidebar'>
            <div className='PageContainer__collapsedContent'>
              <ButtonSecondary
                className='PageContainer__sidebarToggle u-marginRight-12'
                icon='navigationMenu'
                onClick={onToggleSidebar}
              />
              {sidebarTitle && (
                <h2 className='PageContainer__sidebar-title'>{sidebarTitle}</h2>
              )}
            </div>
            <div className='PageContainer__sidebar-content'>{sidebar}</div>
          </section>
          <section className='col-sm-offset-4 col-sm-8 col-md-offset-3 col-md-9'>
            <div className='PageContainer__content'>{children}</div>
          </section>
        </div>
      ) : (
        <section className='PageContainer__content'>{children}</section>
      )}
    </main>
    <PageFooter />
  </>
);

PageContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  fixedWidth: PropTypes.bool,
  sidebar: PropTypes.node,
  sidebarExpandable: PropTypes.bool,
};

PageContainer.defaultProps = {
  fixedWidth: false,
  sidebarExpandable: false,
};

export default compose(
  withState('isSidebarExpanded', 'setSidebarExpanded', false),
  withHandlers({
    onToggleSidebar:
      ({ isSidebarExpanded, setSidebarExpanded }) =>
      () =>
        setSidebarExpanded(!isSidebarExpanded),
  }),
)(PageContainer);
