import { Helmet } from 'react-helmet';
import PageContainer from '../PageContainer';

const NotFoundPage = () => (
  <PageContainer>
    <Helmet>
      <title>Not Found</title>
    </Helmet>
    <h1>Not Found</h1>
    <p>Page not found</p>
  </PageContainer>
);

export default NotFoundPage;
