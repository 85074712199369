import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import getJobListingTitle from '../../selectors/getJobListingTitle';

/**
 * Renders a job listing record's location name.
 */
export const JobTitle = ({ title }) =>
  title ? <span className='JobTitle'>{title}</span> : null;

JobTitle.propTypes = {
  /**
   * The title of the job listing.
   */
  title: PropTypes.string,
};

/**
 * Connected JobTitle, looks up the placement type provided a `jobListingId` value.
 */
export default connect(
  (state, { jobListingId }) => ({
    title: getJobListingTitle(state, jobListingId),
  }),
  null,
)(JobTitle);
