import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import getJobListingFunctionName from '../../selectors/getJobListingFunctionName';

/**
 * Renders a job listing record's location name.
 */
export const JobFunctionName = ({ functionName }) =>
  functionName ? <span className='JobFunctionName'>{functionName}</span> : null;

JobFunctionName.propTypes = {
  /**
   * The function name for the job listing.
   * @type {[type]}
   */
  functionName: PropTypes.string,
};

/**
 * Connected JobFunctionName, looks up the location name provided a `jobListingId` value.
 */
export default connect(
  (state, { jobListingId }) => ({
    functionName: getJobListingFunctionName(state, jobListingId),
  }),
  null,
)(JobFunctionName);
