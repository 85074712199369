import url from 'url';
import JobShareLink from './JobShareLink';

export const createMailtoShareLink = (jobListing, jobUrl) =>
  url.format({
    protocol: 'mailto',
    query: {
      title: jobListing.get('jobTitle'),
      subject: `${jobListing.get('jobFunctionName')} Opportunity`,
      body: jobUrl,
    },
  });

const JobShareMailtoLink = ({ jobListing, jobUrl }) => (
  <JobShareLink
    href={jobListing && createMailtoShareLink(jobListing, jobUrl)}
    icon='mailTo'
  />
);

export default JobShareMailtoLink;
