import PropTypes from 'prop-types';
import {
  compose,
  onlyUpdateForPropTypes,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import Form from 'react-bootstrap/Form';
import FilterLabel from './FilterLabel';

import './CheckboxFiltersFormGroup.scss';

const CheckboxFiltersFormGroup = ({
  itemCounts,
  onItemChange,
  selectedItems,
}) => (
  <div className='CheckboxFiltersFormGroup'>
    {itemCounts &&
      Object.keys(itemCounts).map((name) => (
        <Form.Group controlId={name} key={name}>
          <Form.Check
            checked={selectedItems.includes(name)}
            label={
              <FilterLabel
                className='u-truncate u-block'
                count={itemCounts[name]}
              >
                {name}
              </FilterLabel>
            }
            onChange={onItemChange}
            title={`${name} (${itemCounts[name]})`}
            value={name}
          />
        </Form.Group>
      ))}
  </div>
);

const propTypes = {
  itemCounts: PropTypes.objectOf(PropTypes.number),

  /**
   * Called when a single item is changed (it's `checked` value is changed).
   * The first argument will be the synthentic event from the onChange event of the Checkbox
   */
  onItemChange: PropTypes.func.isRequired,

  selectedItems: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

CheckboxFiltersFormGroup.propTypes = propTypes;

export default compose(
  setDisplayName('withHandlers(CheckboxFiltersFormGroup)'),
  onlyUpdateForPropTypes,
  setPropTypes({
    /**
     * The items keyed by item name whose values are the count of total items
     */
    itemCounts: propTypes.itemCounts,

    /**
     * Called when the array of selectedItems changes. The first argument is the new array of
     * selected items.
     */
    onSelectedItemsChange: PropTypes.func.isRequired,

    /**
     * An array containing the currently selected ("checked") items
     */
    selectedItems: propTypes.selectedItems,
  }),
  withHandlers({
    onItemChange:
      ({ onSelectedItemsChange, selectedItems }) =>
      (e) => {
        const { checked, value } = e.currentTarget;
        if (checked && !selectedItems.includes(value)) {
          onSelectedItemsChange(selectedItems.concat(value));
        } else if (!checked) {
          onSelectedItemsChange(
            selectedItems.filter((filter) => filter !== value),
          );
        }
      },
  }),
)(CheckboxFiltersFormGroup);
