import { connect } from 'react-redux';
import getJobListingPlacementType from '../../selectors/getJobListingPlacementType';

/**
 * A higher order component that filters a list of jobListingIds based on an array of
 * placement type filter values (providwed as a `placementTypeFilters` prop)
 */
export default connect((state, { jobListingIds, placementTypeFilters }) => {
  if (jobListingIds && placementTypeFilters.length) {
    return {
      jobListingIds: jobListingIds.filter((id) =>
        placementTypeFilters.includes(getJobListingPlacementType(state, id)),
      ),
    };
  }

  return {};
}, null);
